import * as React from 'react'
import DialogContentMui from '@material-ui/core/DialogContent'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: 'flex !important',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
}))

type DialogContentProps = { className?: string; children: React.ReactNode }

const DialogContent: React.FC<DialogContentProps> = ({ children, ...props }) => {
  const classes = useStyles()
  return (
    <DialogContentMui {...props} className={props.className ?? classes.dialogContent}>
      {children}
    </DialogContentMui>
  )
}

export default DialogContent
