const CLIENT_ID = process.env.REACT_APP_OKTA_ADMIN_SPA_CLIENT_ID || 'clientId'
const ISSUER = `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`
const REDIRECT_URI = `${window.location.origin}/login/callback`

interface AuthFeatures {
  multiOptionalFactorEnroll: boolean
}

export interface OktaConfig {
  clientId: string
  issuer: string
  redirectUri: string
  scopes: string[]
  pkce: boolean
  features?: AuthFeatures
}

export const oktaWidgetConfig: OktaConfig = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  features: { multiOptionalFactorEnroll: true },
}
