import { stringify } from 'query-string'
import { GetListParams, GetOneParams, UpdateParams, CreateParams, GetManyParams } from 'react-admin'
import axios from 'axios'

import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER, LEARNING_SERVICE_ENDPOINT } from 'utils/constants'
import { Language } from 'types'

class LanguagesProvider {
  public baseUrl: string

  public constructor() {
    this.baseUrl = LEARNING_SERVICE_ENDPOINT
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination
    const { field = DEFAULT_SORT_FIELD, order = DEFAULT_SORT_ORDER } = params.sort

    const query = {
      skip: (page - 1) * perPage,
      limit: perPage,
      field,
      order,
      q: params.filter.q,
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`

    return axios
      .get(url)
      .then(response => {
        const {
          data: { items: required_languages = undefined },
          count: total,
        } = response.data

        if (!required_languages) {
          Promise.reject()
        }

        const data = required_languages.map((item: Language) => {
          return {
            ...item,
            id: item.id,
            name: item.value,
          }
        })

        return Promise.resolve({
          data,
          total,
        })
      })

      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { data: value } = response.data

        if (!value) {
          Promise.reject()
        }

        return Promise.resolve({
          value,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getMany = async (resource: string, params: GetManyParams) => {
    const { ids } = params
    const url = `${this.baseUrl}/${resource}/get_many/`

    return axios
      .post(url, { required_languages: ids })
      .then(response => {
        const { data: value } = response.data

        if (!value) {
          Promise.reject()
        }

        return Promise.resolve({
          value,
        })
      })
      .catch(err => Promise.reject(err))
  }
}

export default LanguagesProvider
