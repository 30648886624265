import * as React from 'react'
import PropTypes from 'prop-types'
import { useRecordContext } from 'react-admin'

type TermsFieldProps = { source: string; label?: string; record?: any }
const TermsField: React.FC<TermsFieldProps> = props => {
  const record = useRecordContext(props)
  const termString = record?.terms.map(val => val.name).join(', ') || ''
  return <>{termString}</>
}

TermsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export default TermsField
