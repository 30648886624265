import * as yup from 'yup'
import { StatusTypes } from './classValidation'

export type BulkEditFieldTypeNames = 'status' | 'cost' | 'host_institution' | 'terms' | 'cip_codes'

type BulkEditFieldType = {
  name: BulkEditFieldTypeNames
  label: string
  value: string
  options?: any
  defaultValue?: any
}

type FieldType = {
  status: BulkEditFieldType
  cost: BulkEditFieldType
  host_institution: BulkEditFieldType
  terms: BulkEditFieldType
  cip_codes: BulkEditFieldType
}

type BulkEditStatusOptions = {
  name: BulkEditFieldTypeNames
  value: StatusTypes
  label: StatusTypes
}

export const statusOptions: BulkEditStatusOptions[] = [
  { name: 'status', value: 'Active', label: 'Active' },
  { name: 'status', value: 'Inactive', label: 'Inactive' },
  { name: 'status', value: 'Draft', label: 'Draft' },
]

export const fieldTypes: FieldType = {
  status: {
    name: 'status',
    label: 'Status',
    value: 'status',
    options: statusOptions,
  },
  cost: {
    name: 'cost',
    label: 'Class Cost',
    value: 'cost',
    defaultValue: 0,
  },
  host_institution: {
    name: 'host_institution',
    label: 'Host Institution',
    value: 'host_institution',
    options: {},
  },
  terms: {
    name: 'terms',
    label: 'Terms',
    value: 'terms',
    options: {},
  },
  cip_codes: {
    name: 'cip_codes',
    label: 'Area of Study',
    value: 'cip_codes',
    options: {},
  },
}

export const classStatusSchema = yup.object().shape({
  formField: yup.string().required(),
  formValue: yup.string().required(),
})

export const classHostInstitutionSchema = yup.object().shape({
  formField: yup.string().required(),
  formValue: yup.string().required(),
})

export const classTermSchema = yup.object().shape({
  formField: yup.string().required(),
  formValue: yup.array().required(),
})

export const classCipCodeSchema = yup.object().shape({
  formField: yup.string().required(),
  formValue: yup.array().required(),
})

export const classCostSchema = yup.object().shape({
  formField: yup.string().required(),
  formValue: yup
    .number()
    .transform(value => (Number.isNaN(value) ? '' : value))
    .typeError('You must enter a valid number.')
    .min(0, 'Min value 0.')
    .required('Class Cost is a required field.'),
})
