import React from 'react'
import { TextInput } from 'react-admin'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '12px',
  },
  search: {
    maxWidth: '608px',
    padding: 0,
    margin: 0,
    '& p': {
      display: 'none',
    },
  },
})

type SearchFilterProps = {
  alwaysOn: boolean
  source?: string
  className?: string
}

const SearchQueryFilter: React.FC<SearchFilterProps> = ({ alwaysOn, source = 'Search', className }) => {
  const classes = useStyles()
  return (
    <>
      <SearchIcon className={classes.searchIcon} />
      <TextInput
        className={`${classes.search} ${className}`}
        name="q"
        source={source}
        resettable
        alwaysOn={alwaysOn}
      />
    </>
  )
}

export default SearchQueryFilter
