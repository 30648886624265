import ProgramList from './ProgramList'
import ProgramEdit from './ProgramEdit'
import ProgramCreate from './ProgramCreate'
import { ProgramIcon } from 'components/Icons'

const programs = {
  list: ProgramList,
  edit: ProgramEdit,
  create: ProgramCreate,
  icon: ProgramIcon,
}

export default programs
