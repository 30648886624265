import { stringify } from 'query-string'
import { GetListParams, GetOneParams, UpdateParams, CreateParams, UpdateManyParams } from 'react-admin'
import axios from 'axios'

import {
  DEFAULT_SORT_FIELD,
  DEFAULT_SORT_ORDER,
  LEARNING_SERVICE_ENDPOINT,
  ADMIN_SERVICE_ENDPOINT,
} from 'utils/constants'
import { filterRelationalData, exportClasses } from 'utils/classes'
import type { ClassPresignParams, ClassUploadParams, GetListFilteredParams } from 'types'
import { csvExporter } from 'utils/files'
import { buildQueryParams } from 'utils/queryParams'
import { fetchAllRecords } from 'utils/requests'

class ClassProvider {
  public baseUrl: string

  public constructor() {
    this.baseUrl = LEARNING_SERVICE_ENDPOINT
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination
    const { field = DEFAULT_SORT_FIELD, order = DEFAULT_SORT_ORDER } = params.sort

    const query = {
      ...params.filter,
      skip: (page - 1) * perPage,
      limit: perPage,
      field,
      order,
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public create = async (resource: string, params: CreateParams) => {
    const { data: body } = params

    const url = `${this.baseUrl}/${resource}/`

    return axios
      .post(url, filterRelationalData(body))
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public update = async (resource: string, params: UpdateParams) => {
    const { data: body } = params
    const url = `${this.baseUrl}/${resource}/${body.id}`

    return axios
      .put(url, filterRelationalData(body))
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public archive = async (resource: string, params: UpdateParams) => {
    const { id } = params
    const url = `${this.baseUrl}/${resource}/archive/${id}`

    return axios
      .put(url, {})
      .then(response => {
        if (response.status !== 204) {
          return Promise.reject(response.statusText)
        }

        return Promise.resolve({ data: {} })
      })
      .catch(err => Promise.reject(err))
  }

  public batch_archive = async (resource: string, params: UpdateParams) => {
    const url = `${this.baseUrl}/${resource}/archive/`

    return axios
      .put(url, { classes_ids: params['classes_ids'] })
      .then(response => {
        if (response.status !== 204) {
          return Promise.reject(response.statusText)
        }

        return Promise.resolve({ data: {} })
      })
      .catch(err => Promise.reject(err))
  }

  // This may not work for API that uses DataStore because
  // DataStore works with a _version field that needs to be properly set
  public updateMany = async (resource: string, params: UpdateManyParams) => {
    const { data, ids } = params

    const url = `${this.baseUrl}/${resource}/update_many/`

    return axios
      .put(url, { ids, data })
      .then(response => {
        const {
          data: { data, errors },
        } = response

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          errors,
        })
      })
      .catch(err => Promise.reject(err))
  }

  // Disabling eslint rule here since I don't know how ReactAdmin is calling this behind the scenes
  // eslint-disable-next-line class-methods-use-this
  public presignUrl = async (resource: string, params: ClassPresignParams) => {
    const { data: body } = params
    const formData = new FormData()
    formData.append('class_id', body.class_id)
    formData.append('file', body.file)
    const url = `${ADMIN_SERVICE_ENDPOINT}/${resource}/syllabus-presign`

    return axios
      .post(url, formData)
      .then(response => {
        const { url, fields, cloudfront_url } = response.data

        if (!fields || !url || !cloudfront_url) {
          Promise.reject()
        }

        return Promise.resolve({
          data: {
            url,
            fields,
            cloudfront_url,
          },
        })
      })
      .catch(err => Promise.reject(err))
  }

  // Disabling eslint rule here since I don't know how ReactAdmin is calling this behind the scenes
  // eslint-disable-next-line class-methods-use-this
  public uploadFile = async (resource: string, params: ClassUploadParams) => {
    const { data: body } = params
    const formData = new FormData()
    const fields = Object.keys(body.fields)
    fields.forEach(field => {
      formData.append(field, body.fields[field])
    })
    formData.append('file', body.file)

    return axios
      .post(body.url, formData)
      .then(response => {
        if (response?.statusText === 'No Content') {
          return Promise.resolve({
            data: {
              message: 'Upload complete',
            },
          })
        }
        Promise.reject()
        return null
      })
      .catch(err => Promise.reject(err))
  }

  public exportRecords = async (resource: string, params: GetListFilteredParams) => {
    const { limit, total, filter } = params
    const getListPromise = async (page: number) =>
      this.getList(resource, buildQueryParams({ page, perPage: limit, filter })).then(({ data }) => data)

    const totalPages: number = Math.ceil(total / limit)
    return fetchAllRecords(totalPages, getListPromise)
      .then(data => {
        csvExporter(data, exportClasses, resource)
        return Promise.resolve({ data })
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}

export default ClassProvider
