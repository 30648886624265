import React, { cloneElement, ReactElement } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ContentSave from '@material-ui/icons/Save'
import classnames from 'classnames'
import { useTranslate, RedirectionSideEffect, OnSuccess, OnFailure, TransformData } from 'ra-core'

import { sanitizeButtonRestProps, Button, ButtonProps } from './Button'

const useStyles = makeStyles(
  (theme: Theme) => ({
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: theme.icons.xSmallIcon,
  }),
  { name: 'RaSaveButton' },
)

type SaveButtonProps = {
  id?: string
  className?: string
  onSave?: (values: object, redirect: RedirectionSideEffect) => void
  onSuccess?: OnSuccess
  onFailure?: OnFailure
  transform?: TransformData
  icon?: ReactElement
  invalid?: boolean
  label?: string
  onClick?: () => void
  saving?: boolean
  type?: 'submit' | 'button'
  dataTestId?: string
}

const SaveButton: React.FC<SaveButtonProps & ButtonProps> = props => {
  const {
    id,
    className,
    disabled = false,
    type = 'submit',
    saving = false,
    onClick,
    label = 'ra.action.save',
    variant = 'contained',
    icon = <ContentSave />,
    color = 'primary',
    dataTestId = 'saveButton',
    ...rest
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const displayedLabel = label && translate(label, { _: label })
  const isDisabled = disabled || saving
  return (
    <Button
      id={id}
      className={className}
      variant={variant}
      type={type}
      onClick={onClick}
      color={color}
      aria-label={displayedLabel}
      disabled={isDisabled}
      data-testid={dataTestId}
      {...sanitizeButtonRestProps(rest)}
    >
      <>
        {saving ? (
          <CircularProgress size={18} thickness={2} data-testid="circularProgress" />
        ) : (
          cloneElement(icon, {
            className: classnames(classes.leftIcon, classes.icon),
          })
        )}
        {displayedLabel}
      </>
    </Button>
  )
}

export { SaveButton }
export type { SaveButtonProps }
