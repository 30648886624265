import * as React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Container, Grid, Paper, Typography, Button } from '@material-ui/core'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(6),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  secondary: {
    flex: 1,
    margin: '25px 0 15px 0',
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  heading: {
    padding: '0',
    margin: '0',
  },
  icon: {
    marginRight: '15px',
    color: 'red',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()

  return (
    <div role="alert" className={classes.errorContainer}>
      <div className={classes.headingContainer}>
        <ErrorTwoToneIcon className={classes.icon} />
        <Typography className={classes.heading} component="h2" variant="h6" gutterBottom>
          Oops, there was an error.
        </Typography>
      </div>
      <pre>{error?.message || JSON.stringify(error)}</pre>
      <Typography color="textSecondary" className={classes.secondary}>
        Try refreshing the page.
      </Typography>
      <Button type="submit" onClick={resetErrorBoundary} variant="contained" color="primary">
        Refresh
      </Button>
    </div>
  )
}

const FullPageErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.center}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={classes.paper}>
                <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

export default FullPageErrorFallback
