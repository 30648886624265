import { stringify } from 'query-string'
import { GetListParams, GetManyParams, GetOneParams } from 'react-admin'
import axios from 'axios'

import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER, LEARNING_SERVICE_ENDPOINT } from 'utils/constants'
import { prepFilters } from 'utils/filters'

class CreditUnitProvider {
  public baseUrl: string

  public constructor() {
    this.baseUrl = LEARNING_SERVICE_ENDPOINT
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { pagination, sort, filter = {} } = params
    const { page, perPage } = pagination
    const { field = DEFAULT_SORT_FIELD, order = DEFAULT_SORT_ORDER } = sort

    const query = {
      skip: (page - 1) * perPage,
      limit: perPage,
      order,
      field,
      ...prepFilters(filter),
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`

    return axios
      .get(url)
      .then(response => {
        const { items: data, count: total } = response.data.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { items: data, count: total } = response.data.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getMany = async (resource: string, params: GetManyParams) => {
    const { ids } = params
    const url = `${this.baseUrl}/${resource}/get_many/`

    return axios
      .post(url, { credit_unit_ids: ids })
      .then(response => {
        const { items: data, count: total } = response.data.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }
}

export default CreditUnitProvider
