import * as React from 'react'
import { Edit, EditProps } from 'react-admin'
import ProgramForm from '../ProgramForm'

const ProgramEdit: React.FC<EditProps> = props => {
  return (
    <Edit {...props} title="Edit Program" component="div">
      <ProgramForm />
    </Edit>
  )
}

export default ProgramEdit
