import * as React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, ShowProps, Labeled } from 'react-admin'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  row: { display: 'flex', width: '100%' },
  column: { display: 'flex', flexDirection: 'column', width: '100%' },
  item: { marginBottom: '15px' },
})

type LabeledTextFieldProps = {
  source: string
  label: string
  isDate?: boolean
}

const LabeledTextField = ({ source, label, isDate = false }: LabeledTextFieldProps) => {
  const classes = useStyles()

  return (
    <Labeled className={classes.item} source={label}>
      {isDate ? (
        <DateField label={label} source={source} />
      ) : (
        <TextField label={label} source={source} emptyText="n/a" />
      )}
    </Labeled>
  )
}

type HostInstitutionTitleProps = ShowProps & { record?: any }

const HostInstitutionTitle: React.FC<HostInstitutionTitleProps> = ({ record }) => {
  return <span>Host Institution: {record.name}</span>
}

type HostInstitutionShowProps = ShowProps & { record?: any }

const HostInstitutionShow: React.FC<HostInstitutionShowProps> = props => {
  const classes = useStyles()

  return (
    <Show title={<HostInstitutionTitle {...props} />} {...props}>
      <SimpleShowLayout>
        <div className={classes.row}>
          <div className={classes.column}>
            <LabeledTextField label="Name" source="name" />
            <LabeledTextField label="Class Credit Units" source="credit_unit.name" />
            <LabeledTextField label="Shipping Address Country" source="shipping_address_country" />
            <LabeledTextField label="Finance Code" source="finance_code" />
            <LabeledTextField label="Salesforce Id" source="id" />
            <LabeledTextField label="Resgistration Type" source="registration_type" />
            <LabeledTextField label="Created Date" source="created_at" isDate />
            <LabeledTextField label="Updated By" source="updated_by" />
          </div>
          <div className={classes.column}>
            <LabeledTextField label="Country" source="country.name" />
            <LabeledTextField label="Shipping address line 1" source="shipping_address_line_1" />
            <LabeledTextField label="Shipping Address City" source="shipping_address_city" />
            <LabeledTextField label="Shipping Address Postal Code" source="shipping_address_postal_code" />
            <LabeledTextField label="Netsuite Id" source="netsuite_id" />
            <LabeledTextField label="Assessment Structure" source="assessment_structure" />
            <LabeledTextField label="Accreditation" source="accreditation" />
            <LabeledTextField label="Last Updated" source="updated_at" isDate />
          </div>
        </div>
      </SimpleShowLayout>
    </Show>
  )
}

export default HostInstitutionShow
