import { TextInput, TextInputProps } from 'react-admin'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  filter: {
    minWidth: '200px !important',
  },
  autoCompleteFilter: {
    maxWidth: '200px !important',
    '& input': {
      maxHeight: '15px',
    },
  },
  searchInput: {
    width: '100%',
  },
  searchLabel: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'initial',
    '& svg': {
      marginRight: '5px',
    },
  },
})

const StyledSearchInput: React.FC<TextInputProps> = ({ label, source }) => {
  const classes = useStyles()
  return (
    <TextInput
      id="full-text-search"
      source={source}
      className={`${classes.filter} ${classes.searchInput}`}
      label={
        <div className={classes.searchLabel}>
          <SearchIcon />
          <span>{label}</span>
        </div>
      }
      variant="filled"
    />
  )
}

export default StyledSearchInput
