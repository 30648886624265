import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

import DataProvider from './DataProvider'
import StudentProvider from './StudentProvider'
import CountryProvider from './CountryProvider'
import HostInstitutionProvider from './HostInstitutionProvider'
import ProgramProvider from './ProgramProvider'
import CreditUnitProvider from './CreditUnitProvider'
import SourceSystemProvider from './SourceSystemProvider'
import ClassProvider from './ClassProvider'
import TermProvider from './TermProvider'
import CIPCodeProvider from './CIPCodeProvider'
import LanguagesProvider from './LanguagesProvider'
import EducationLevelProvider from './EducationLevelProvider'
import WorkLocationProvider from './WorkLocationProvider'
import ProficiencyLevelProvider from './ProficiencyLevelProvider'

import { AUTHENTICATED_ROUTES } from 'utils/constants'
import * as Sentry from '@sentry/react'

const authenticatedUrl = (requestUrl: string | undefined) =>
  requestUrl ? AUTHENTICATED_ROUTES.find(route => requestUrl.includes(route as string)) : ''

const addAuthenticationMiddleware = () => {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      const requestUrl: string | undefined = config.url

      if (authenticatedUrl(requestUrl)) {
        const tokens = localStorage.getItem('okta-token-storage')
        if (tokens) {
          const { accessToken } = JSON.parse(tokens)
          // eslint-disable-next-line no-param-reassign
          config.headers.common.authorization = `Bearer ${accessToken.accessToken}`
        }
      }

      return config
    },
    error => Promise.reject(error),
  )
}

const addSentryErrorResponseCatch = () => {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 422) {
        Sentry.captureException({
          request_data: JSON.stringify(error.config.data),
          request_headers: JSON.stringify(error.config.headers),
          request_url: error.config.url,
          request_method: error.config.method,
          response_status: error.response.status,
          response_data: JSON.stringify(error.response.data),
          response_headers: JSON.stringify(error.response.headers),
        })
      }
      return Promise.reject(error)
    },
  )
}

const dataProviders = {
  students: new StudentProvider(),
  countries: new CountryProvider(),
  host_institutions: new HostInstitutionProvider(),
  programs: new ProgramProvider(),
  classes: new ClassProvider(),
  terms: new TermProvider(),
  cip_codes: new CIPCodeProvider(),
  credit_units: new CreditUnitProvider(),
  source_systems: new SourceSystemProvider(),
  languages: new LanguagesProvider(),
  education_levels: new EducationLevelProvider(),
  work_locations: new WorkLocationProvider(),
  proficiency_levels: new ProficiencyLevelProvider(),
}

addAuthenticationMiddleware()
addSentryErrorResponseCatch()
const dataProvider = new DataProvider(dataProviders)
export default dataProvider
