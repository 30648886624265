import * as React from 'react'
import OktaSignIn from '@okta/okta-signin-widget'
import { Tokens, AuthApiError } from '@okta/okta-auth-js'
import { OktaConfig } from 'oktaConfig'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import logo from 'assets/api_abroad_logo.png'

interface OktaSignInWidgetProps {
  config: OktaConfig
  onSuccess: (tokens: Tokens) => void
  onError: (err: AuthApiError) => void
}

type Cleanup = () => void

const OktaSignInWidget: React.FC<OktaSignInWidgetProps> = ({ config, onSuccess, onError }) => {
  const widgetRef = React.useRef<HTMLDivElement>(null)

  React.useEffect((): Cleanup | void => {
    const { issuer, clientId, redirectUri, scopes } = config
    if (!widgetRef.current) return undefined

    const widget = new OktaSignIn({
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      logo,
      i18n: {
        en: {
          'primaryauth.title': 'Sign in',
        },
      },
      authParams: {
        issuer,
        scopes,
      },
    })

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError)

    return () => widget.remove()
  }, [config, onSuccess, onError])

  return <div ref={widgetRef} />
}
export default OktaSignInWidget
