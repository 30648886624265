import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
  selectInput: {
    width: '100%',
    borderRadius: '5px 5px 0 0',
    textTransform: 'capitalize',
    [`& div[class*='MuiFilledInput-root']`]: {
      paddingTop: '11px',
      [`& div[class*='MuiChip-root']:nth-of-type(1)`]: {
        marginTop: '8px',
      },
    },
  },
}))

type BulkEditAutocompleteSelectType = {
  id?: string
  onChange?: any
  options: []
  label: string
  variant?: TextFieldProps['variant']
  multiple?: boolean
}

const BulkEditAutocompleteSelect: React.FC<BulkEditAutocompleteSelectType> = ({
  id,
  onChange,
  options,
  label,
  variant = 'filled',
  multiple = false,
}) => {
  const classes = useStyles()
  return (
    <>
      <Autocomplete
        id={id}
        multiple={multiple}
        renderInput={params => <TextField {...params} label={label} variant={variant} />}
        autoComplete
        getOptionLabel={(option: any) => option.label}
        onChange={onChange}
        options={options}
        className={classes.selectInput}
      />
    </>
  )
}

export default BulkEditAutocompleteSelect
