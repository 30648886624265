import { Identifier, SortPayload } from 'react-admin'

import { DEFAULT_SORT_ORDER, DEFAULT_SORT_FIELD } from 'utils/constants'

type SortFunction = (sort: string, order?: string | undefined) => void

interface ICustomListContext {
  sessionData: { [x: string]: any }
  pageData: { [x: string]: any }
  selectedRecords: any[]
  selectedIds: Identifier[]
  currentSort: SortPayload
  sortFunction: SortFunction
  getRecord: (id: Identifier) => any
  unSelectAll: () => void
  selectAll: (records: any[]) => void
  selectRecord: (id: Identifier) => void
  unSelectRecord: (id: Identifier) => void
  setListContext: (type: CustomListAction, payload: any) => void
}

const defaultInitialState: ICustomListContext = {
  sessionData: {},
  selectedRecords: [],
  pageData: {},
  selectedIds: [],
  currentSort: { field: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER },
  sortFunction: () => undefined,
  getRecord: (id: Identifier) => undefined,
  unSelectAll: () => undefined,
  selectAll: (records: any[]) => undefined,
  selectRecord: (id: Identifier) => undefined,
  unSelectRecord: (id: Identifier) => undefined,
  setListContext: (type: CustomListAction, payload: any) => undefined,
}

enum CustomListAction {
  setPageData = 'SET_PAGE_DATA',
  setSessionData = 'SET_SESSION_DATA',
  setCurrentSort = 'SET_CURRENT_SORT',
  setListSelectedIds = 'SET_SELECTED_IDS',
  setSelectedRecords = 'SET_SELECTED_RECORDS',
  setSortFunction = 'SET_SORT_FUNCTION',
}

type CustomListActionType = {
  type: CustomListAction
  payload: any
}

const customListReducer = (state, action: CustomListActionType) => {
  switch (action.type) {
    case CustomListAction.setSelectedRecords:
      return { ...state, selectedRecords: action.payload }
    case CustomListAction.setSessionData:
      return { ...state, sessionData: action.payload }
    case CustomListAction.setPageData:
      return { ...state, pageData: action.payload }
    case CustomListAction.setCurrentSort:
      return { ...state, currentSort: action.payload }
    case CustomListAction.setListSelectedIds:
      return { ...state, selectedIds: action.payload }
    case CustomListAction.setSortFunction:
      return { ...state, sortFunction: action.payload }
    default:
      throw new Error()
  }
}

export { customListReducer, defaultInitialState, CustomListAction }
export type { ICustomListContext, SortFunction }
