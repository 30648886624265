import * as React from 'react'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Report'
import History from '@material-ui/icons/History'
import { Title, useTranslate } from 'react-admin'
import { Fragment, ReactElement } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '1em',
      },
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: '2em',
      height: '2em',
      marginRight: '0.5em',
    },
    panel: {
      marginTop: '1em',
      maxWidth: '60em',
    },
    panelDetails: {
      whiteSpace: 'pre-wrap',
    },
    toolbar: {
      marginTop: '2em',
    },
    advice: {
      marginTop: '2em',
    },
  }),
  { name: 'RaError' },
)

const goBack = () => {
  window.history.go(-1)
}

interface RACustomErrorProps {
  error?: Error
  errorInfo?: React.ErrorInfo
  title?: string | ReactElement<any>
}

export const RACustomError: React.FC<RACustomErrorProps> = props => {
  const { error = { message: '' }, errorInfo, title, ...rest } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  // Effect that resets the error state whenever the location changes
  React.useEffect(() => {
    Sentry.withScope(scope => {
      scope.setExtras({ errorInfo })
      Sentry.captureException(error)
    })
  }, [error, errorInfo])

  return (
    <Fragment>
      {title && <Title title={title} />}
      <div className={classes.container} {...rest}>
        <h1 className={classes.title} role="alert">
          <ErrorIcon className={classes.icon} />
          {translate('ra.page.error')}
        </h1>
        <div>{translate('ra.message.error')}</div>
        {process.env.NODE_ENV !== 'production' && (
          <>
            <Accordion className={classes.panel}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {translate(error.toString(), {
                  _: error.toString(),
                })}
              </AccordionSummary>
              {errorInfo && (
                <AccordionDetails className={classes.panelDetails}>{errorInfo.componentStack}</AccordionDetails>
              )}
            </Accordion>

            <div className={classes.advice}>
              <Typography align="center">Need help with this error? Try the following:</Typography>
              <Typography component="div">
                <ul>
                  <li>
                    Check the <a href="https://marmelab.com/react-admin/Readme.html">react-admin documentation</a>
                  </li>
                  <li>
                    Search on <a href="https://stackoverflow.com/questions/tagged/react-admin">StackOverflow</a> for
                    community answers
                  </li>
                  <li>
                    Get help from the core team via{' '}
                    <a href="https://marmelab.com/ra-enterprise/#fromsww">react-admin Enterprise Edition</a>
                  </li>
                </ul>
              </Typography>
            </div>
          </>
        )}
        <div className={classes.toolbar}>
          <Button variant="contained" startIcon={<History />} onClick={goBack}>
            {translate('ra.action.back')}
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

export default RACustomError
