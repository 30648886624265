import React from 'react'
import { TextInput, TextField, DateField, Record } from 'react-admin'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles } from '@material-ui/core/styles'

import { CustomList } from 'components/CustomList'
import { CustomDatagrid } from 'components/CustomDatagrid'
import { PROGRAMS_PATH, PROGRAMS_RESOURCE } from 'utils/constants'

const useStyles = makeStyles(() => ({
  content: {
    boxShadow: 'none',
  },
  root: {
    flexGrow: 1,
  },
  bulkActionsDisplayed: {
    marginTop: '0',
  },
  dialogRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px',
    borderRadius: '5px',
  },
  button: {
    padding: '6px 26px',
  },
}))

const listFilters = [
  <TextInput
    style={{
      width: '100%',
      position: 'absolute',
      zIndex: '0',
      borderRadius: '10px 10px 0 0',
    }}
    label="ra.action.search"
    source="q"
    key="search-bar"
    resettable
    alwaysOn
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
]

type ProgramSelectorProps = {
  setSessionSelectedPrograms: React.Dispatch<React.SetStateAction<Record[]>>
  selectedPrograms: Record[]
}

const ProgramList: React.FC<ProgramSelectorProps> = ({ selectedPrograms, setSessionSelectedPrograms, ...props }) => {
  const classes = useStyles()

  return (
    <CustomList
      filters={listFilters}
      classes={{ bulkActionsDisplayed: classes.bulkActionsDisplayed }}
      exporter={false}
      basePath={PROGRAMS_PATH}
      hasCreate={false}
      bulkActionButtons={false}
      hasEdit={false}
      hasList
      hasShow={false}
      resource={PROGRAMS_RESOURCE}
      selectedRecords={selectedPrograms}
      setSelectedRecordsCallback={setSessionSelectedPrograms}
    >
      <CustomDatagrid resource="programs" {...props}>
        <TextField label="Program Name" source="name" />
        <TextField label="Salesforce ID" source="salesforce_id" />
        <TextField label="Updated By" source="updated_by" />
        <DateField label="Created Date" source="created_at" />
        <DateField label="Last Updated" source="updated_at" />
      </CustomDatagrid>
    </CustomList>
  )
}

export default ProgramList
