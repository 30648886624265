import * as React from 'react'
import programLogo from 'assets/program_icon.svg'
import hostInstitutionIcon from 'assets/host_institution_icon.svg'
import classLogo from 'assets/class_icon.svg'

const HostInstitutionIcon: React.FC = () => {
  return <img src={hostInstitutionIcon} alt="Host Institution" />
}

const ProgramIcon: React.FC = () => {
  return <img src={programLogo} alt="Program" />
}

const ClassIcon: React.FC = () => {
  return <img src={classLogo} alt="Program" />
}

export { HostInstitutionIcon, ClassIcon, ProgramIcon }
