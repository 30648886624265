import React from 'react'
import { Identifier, List, ListProps, Record } from 'react-admin'

import { DEFAULT_LIMIT, DEFAULT_SORT_ORDER, DEFAULT_SORT_FIELD } from 'utils/constants'
import { CustomDatagridProvider } from '../CustomDatagrid/CustomDatagridProvider'
import { CustomListProvider } from './CustomListProvider'
import { omit } from 'utils/objects'

type CustomListProps = {
  selectedItems?: Record[]
  selectedIds?: Identifier[]
  bulkActionButtons?: React.ReactElement | false
  selectedRecords?: Record[]
  setSelectedRecordsCallback?: React.Dispatch<React.SetStateAction<Record[]>>
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

const CustomList: React.FC<CustomListProps & ListProps & CustomListProps> = props => {
  const {
    children,
    classes,
    filters,
    selectedRecords,
    setSelectedRecordsCallback,
    bulkActionButtons,
    exporter,
    ...rest
  } = props

  return (
    <CustomListProvider selectedRecords={selectedRecords} setSelectedRecordsCallback={setSelectedRecordsCallback}>
      <List
        {...rest}
        exporter={exporter}
        classes={classes}
        filters={filters}
        perPage={DEFAULT_LIMIT}
        sort={{ field: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER }}
        bulkActionButtons={bulkActionButtons}
      >
        <CustomDatagridProvider {...omit(rest, ['hasEdit', 'hasList'])}>{children}</CustomDatagridProvider>
      </List>
    </CustomListProvider>
  )
}

export { CustomList }
export type { CustomListProps }
