import { stringify } from 'query-string'
import { GetListParams, GetOneParams, UpdateParams, CreateParams, GetManyParams } from 'react-admin'
import axios from 'axios'
import { prepFilters } from '../utils/filters'

import {
  DEFAULT_SORT_FIELD,
  DEFAULT_SORT_ORDER,
  LEARNING_SERVICE_ENDPOINT,
  DEFAULT_LIMIT,
  DEFAULT_SKIP,
} from 'utils/constants'
import { GetManyParamsWithPages } from 'types'

class ProgramProvider {
  public baseUrl: string

  public constructor() {
    this.baseUrl = LEARNING_SERVICE_ENDPOINT
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { pagination, sort, filter } = params
    const { page, perPage } = pagination
    const { field = DEFAULT_SORT_FIELD, order = DEFAULT_SORT_ORDER } = sort
    const query = {
      ...params.filter,
      skip: (page - 1) * perPage,
      limit: perPage,
      field,
      order,
      ...prepFilters(filter),
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`
    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          const msg = `response.data.data=${data}, response=${JSON.stringify(response)}`
          Promise.reject(msg)
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getMany = async (resource: string, params: GetManyParamsWithPages) => {
    const { ids, skip = DEFAULT_SKIP, limit = DEFAULT_LIMIT } = params
    const query = {
      limit,
      skip,
    }
    const url = `${this.baseUrl}/${resource}/get_many/?${stringify(query)}`

    return axios
      .post(url, { program_ids: ids })
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getClasses = async (resource: string, params: GetManyParams) => {
    const { ids } = params
    const url = `${this.baseUrl}/${resource}/classes/`

    return axios
      .post(url, { program_ids: ids })
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject(Error('No associated classes available.'))
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public create = async (resource: string, params: CreateParams) => {
    const { data: body } = params

    const url = `${this.baseUrl}/${resource}/`

    return axios
      .post(url, body)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public update = async (resource: string, params: UpdateParams) => {
    const { data: body } = params

    const url = `${this.baseUrl}/${resource}/${body.id}`

    return axios
      .put(url, body)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public archive = async (resource: string, params: UpdateParams) => {
    const { id } = params
    const url = `${this.baseUrl}/${resource}/archive/${id}`

    return axios
      .put(url, {})
      .then(response => {
        if (response.status !== 204) {
          return Promise.reject(response.statusText)
        }

        return Promise.resolve({ data: {} })
      })
      .catch(err => Promise.reject(err))
  }
}

export default ProgramProvider
