import * as React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Redirect } from 'react-router-dom'
import { Tokens } from '@okta/okta-auth-js'
import OktaSignInWidget from './OktaSignInWidget'
import { OktaConfig } from 'oktaConfig'
import FullPageSpinner from 'components/FullPageSpinner'

interface LoginProps {
  config: OktaConfig
}

const Login: React.FC<LoginProps> = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth()

  if (authState && authState.isPending) {
    return <FullPageSpinner />
  }

  const onError = (): void => {
    // Widget and/or Fallback will handle the error
  }

  // TODO handleLoginRedirect returns a promise, should probably async/await, not sure the ramifications, check back. ARS
  const onSuccess = (tokens: Tokens): void => {
    oktaAuth?.handleLoginRedirect(tokens)
  }

  return authState && authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/dashboard' }} />
  ) : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  )
}

export default Login
