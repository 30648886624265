import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Button, Record, Identifier } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1rem',
    padding: '15px 15px 15px 15px',
  },
  selectedItems: {
    fontSize: '1rem',
    padding: ' 0px 15px 0px 15px',
  },
  gridSelectBtn: {
    textAlign: 'right',
  },
  selectBtn: {
    width: '160px',
    height: '36px',
    margin: '5px 5px 5px 5px',
    padding: '6px 6.5px 6px 0',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#fff',
  },
  selectedList: {
    paddingTop: '5px',
  },
  deleteIcon: {
    color: '#cccccc',
  },
}))

type SelectedListProps = {
  items: Record[]
  unSelectRecord: (id: Identifier) => void
}

const SelectedList: React.FC<SelectedListProps> = ({ items, unSelectRecord }) => {
  const classes = useStyles()
  return (
    <>
      {items.map((item: Record) => {
        const id = `selectedProgram-${item?.id}`
        return (
          <Grid key={id} item xs={12} container data-testid={id}>
            <Grid item xs={9} className={classes.selectedList}>
              {item?.name}
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.deleteIcon} label="" onClick={() => unSelectRecord(item?.id)}>
                <DeleteIcon className={classes.deleteIcon} />
              </Button>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

type ProgramFieldDisplayProps = {
  setShowDialog: (show: boolean) => void
  removeProgram: (id: Identifier) => void
  selectedPrograms: Record[]
}

const ProgramFieldDisplay: React.FC<ProgramFieldDisplayProps> = ({
  setShowDialog,
  removeProgram,
  selectedPrograms = [],
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Program
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.gridSelectBtn}>
          <Button
            className={classes.selectBtn}
            color="primary"
            variant="contained"
            label="Select Programs"
            onClick={() => setShowDialog(true)}
            data-testid="selectProgramsButton"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.selectedItems}>
        <SelectedList items={selectedPrograms} unSelectRecord={removeProgram} />
      </Grid>
    </>
  )
}

export default ProgramFieldDisplay
