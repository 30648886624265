import Flag from '@material-ui/icons/Flag'
import CountryList from './CountryList'
import CountryShow from './CountryShow'

const countryList = {
  list: CountryList,
  show: CountryShow,
  icon: Flag,
}

export default countryList
