import React, { MouseEventHandler, cloneElement } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import IconCancel from '@material-ui/icons/Cancel'
import { ButtonProps } from '@material-ui/core/Button'
import { useTranslate } from 'ra-core'
import { sanitizeButtonRestProps, Button } from './Button'
import classnames from 'classnames'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'relative',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: theme.icons.xSmallIcon,
}))

export type CancelButtonProps = {
  id?: string
  className?: string
  icon?: React.ReactElement
  label?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  color?: string
  dataTestId?: string
}

export const CancelButton: React.FC<CancelButtonProps & ButtonProps> = props => {
  const {
    id,
    onClick,
    className,
    icon = <IconCancel />,
    variant = 'outlined',
    disabled = false,
    color = 'primary',
    label = 'cancel',
    dataTestId = 'cancelButton',
    ...rest
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()
  const displayedLabel = label && translate(label, { _: label })

  return (
    <Button
      id={id}
      className={classnames(classes.button, className)}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      color={color}
      data-testid={dataTestId}
      {...sanitizeButtonRestProps(rest)}
    >
      <>
        {cloneElement(icon, { className: classnames(classes.leftIcon, classes.icon) })}
        {displayedLabel}
      </>
    </Button>
  )
}
