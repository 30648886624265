import * as React from 'react'
import { useCallback, MouseEventHandler } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import SaveIcon from '@material-ui/icons/Save'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useTranslate } from 'ra-core'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(
  theme => ({
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    confirmWarning: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
    buttonSave: {
      color: 'white',
      marginLeft: '24px',
      backgroundColor: '#2a9a99',
      'font-weight': 'bold',
    },
    buttonCancel: {
      color: '#2a9a99',
      marginRight: '24px',
      'font-weight': 'bold',
    },
    mtop: {
      marginTop: '24px',
    },
    mbotom: {
      marginBottom: '32px',
    },
  }),
  { name: 'RaConfirm' },
)

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
const Confirm = (props: ConfirmProps) => {
  const { isOpen, loading, title, content, confirm, cancel, onClose, onConfirm, translateOptions = {} } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const handleConfirm = useCallback(
    e => {
      e.stopPropagation()
      onConfirm(e)
    },
    [onConfirm],
  )

  const handleClick = useCallback(e => {
    e.stopPropagation()
  }, [])

  return (
    <Dialog open={isOpen} onClose={onClose} onClick={handleClick} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title" className={classes.mtop} data-test="modal-confirm-title">
        <Box display="flex" width={500} height={18} alignItems="center" justifyContent="center">
          {translate(title, { _: title, ...translateOptions })}
        </Box>
      </DialogTitle>
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText>
            {translate(content, {
              _: content,
              ...translateOptions,
            })}
          </DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions className={classes.mbotom}>
        <Button
          disabled={loading}
          variant="contained"
          onClick={handleConfirm}
          color="primary"
          className={classes.buttonSave}
          autoFocus
          data-test="bt-save-archive"
        >
          <SaveIcon className={classes.iconPaddingStyle} />
          {translate(confirm, { _: confirm })}
        </Button>
        <div style={{ flex: '1' }} />
        <Button
          disabled={loading}
          onClick={onClose}
          variant="outlined"
          className={classes.buttonCancel}
          data-test="bt-cancel-archive"
        >
          <CancelRoundedIcon className={classes.iconPaddingStyle} />
          {translate(cancel, { _: cancel })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export interface ConfirmProps {
  cancel: string
  classes?: object
  confirm: string
  content: React.ReactNode
  isOpen: boolean
  loading?: boolean
  onClose: MouseEventHandler
  onConfirm: MouseEventHandler
  title: string
  translateOptions?: object
}

Confirm.propTypes = {
  cancel: PropTypes.string,
  classes: PropTypes.object,
  confirm: PropTypes.string,
  content: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

Confirm.defaultProps = {
  cancel: 'ra.action.cancel',
  classes: {},
  confirm: 'ra.action.confirm',
  isOpen: false,
}

export default Confirm
