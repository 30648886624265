import { useMutation, useNotify, useRefresh, useUnselectAll, Confirm, useListContext } from 'react-admin'
import { Button } from '@material-ui/core'
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import Typography from '@material-ui/core/Typography'
import { useState } from 'react'

import ArchiveConfirmationMessage from '../ArchiveConfirmationMessage'

type BulkArchiveButtonProps = {
  resource: string
}

const BulkArchiveButton: React.FC<BulkArchiveButtonProps> = ({ resource }) => {
  const { selectedIds } = useListContext()

  const [isOpenPopup, setOpenPopup] = useState(false)
  const unselectAll = useUnselectAll(resource)
  const notify = useNotify()
  const refresh = useRefresh()

  const [batch_archive, { loading }] = useMutation(
    {
      type: 'batch_archive',
      resource,
      payload: { classes_ids: selectedIds, data: {} },
    },
    {
      onSuccess: data => {
        setTimeout(() => {
          notify(`Selected ${resource} were archived`)
          unselectAll()
          refresh()
        })
        setOpenPopup(false)
      },
      onFailure: err => {
        notify('Oops! something went wrong')
      },
    },
  )

  return (
    <>
      <Button
        color="primary"
        size="small"
        startIcon={<ArchiveOutlinedIcon />}
        disabled={loading}
        onClick={() => {
          setOpenPopup(true)
        }}
      >
        <Typography variant="inherit" noWrap>
          Archive
        </Typography>
      </Button>
      <Confirm
        title="Archive Confirmation"
        loading={loading}
        isOpen={isOpenPopup}
        content={<ArchiveConfirmationMessage />}
        confirm="Save"
        cancel="Cancel"
        onClose={() => {
          setOpenPopup(false)
        }}
        onConfirm={batch_archive}
      />
    </>
  )
}

export default BulkArchiveButton
