import React, { useCallback } from 'react'
import { Security, SecureRoute } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Route, Switch, useHistory } from 'react-router-dom'

import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { oktaWidgetConfig } from './oktaConfig'

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

// Components
import LoginCallback from 'pages/login/LoginCallback'
import FullPageErrorFallback from 'components/FullPageErrorFallback'
import AdminApp from 'AdminApp'
import Login from 'pages/login/Login'

const oktaAuth = new OktaAuth(oktaWidgetConfig)

const LOGIN_CALLBACK_URL = '/login/callback'

export const App: React.FC = () => {
  const history = useHistory()

  const triggerLogin = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/')
  }

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin()
    }
  }

  const restoreOriginalUri = useCallback(async (_oktaAuth: OktaAuth, originalUri: string): Promise<void> => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onReset = (): void => {
    window.location.reload()
  }

  return (
    <ErrorBoundary
      FallbackComponent={FullPageErrorFallback}
      onReset={onReset}
      onError={(error, errorInfo) => {
        Sentry.withScope(scope => {
          scope.setExtras(errorInfo)
          Sentry.captureException(error)
        })
      }}
    >
      <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route
            exact
            path={LOGIN_CALLBACK_URL}
            render={props => <LoginCallback {...props} onAuthResume={triggerLogin} />}
          />
          <SecureRoute path="/dashboard" component={AdminApp} />
          <Route path="/" render={() => <Login config={oktaWidgetConfig} />} />
        </Switch>
      </Security>
    </ErrorBoundary>
  )
}

export default App
