import * as React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: { width: 600 },
  spacer: { height: 20 },
  invoices: { margin: '10px 0' },
})

type StudentShowProps = { record?: any }

const StudentShow: React.FC<StudentShowProps> = ({ record }) => {
  const classes = useStyles()
  const { data, form } = record || {}

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Email: {data ? data.email : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.spacer}>&nbsp;</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Form: {form}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default StudentShow
