import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import apiLogo from 'assets/apiconnect_logo_186x36.png'

const useStyles = makeStyles({
  logo: {
    height: '25px',
    margin: 'auto',
  },
})

const Logo: React.FC = () => {
  const classes = useStyles()
  return <img className={classes.logo} src={apiLogo} alt="api connect logo" />
}

export default Logo
