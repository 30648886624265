// in src/Dashboard.js
import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'

const Dashboard: React.FC = () => (
  <Card>
    <CardHeader title="Welcome to API’s Admin Portal" />
    <CardContent />
  </Card>
)

export default Dashboard
