import * as React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { BrowserRouter as Router } from 'react-router-dom'

import { SENTRY_CONFIG } from 'utils/constants'
import { provideTheme } from 'theme'

Sentry.init({
  ...SENTRY_CONFIG,
  // debug: true,
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <Router>{provideTheme(<App />)}</Router>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
