import React from 'react'
import { DatagridRowProps, Record } from 'react-admin'
import { ListItemActionMenu } from 'components/ListActions'
import ProgramArchiveConfirmationMessage from './ProgramArchiveConfirmationMessage'

type ProgramItemActionMenuProps = {
  record?: Record
}

const ProgramItemActionMenu = React.forwardRef<HTMLInputElement, DatagridRowProps & ProgramItemActionMenuProps>(
  (props, ref) => {
    const { record } = props
    if (record) {
      return (
        <ListItemActionMenu
          resource="programs"
          record={record}
          customConfirmationMessage={<ProgramArchiveConfirmationMessage record={record} />}
        />
      )
    }
    return null
  },
)

export default ProgramItemActionMenu
