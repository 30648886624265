import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Record } from 'react-admin'
import ArchiveListItem from 'components/ListActions/ListItemActions/ArchiveListItemAction'
import CopyListItem from './CopyListItemAction'

interface ListItemActionsMenuProps {
  record: Record
  resource: string
  onCopyItem?: React.MouseEventHandler<HTMLLIElement>
  children?: React.ReactNode
  customConfirmationMessage?: JSX.Element
}

const ListItemActionsMenu = React.forwardRef<HTMLInputElement, ListItemActionsMenuProps>((props, ref) => {
  const { onCopyItem, record, resource, children, customConfirmationMessage } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const copyListItem = onCopyItem ? <CopyListItem onCopyItem={onCopyItem} record={record} /> : null

  return (
    <div className="rowActionsMenu">
      <IconButton
        data-testid={`dropdown-more-button-${record.id}`}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        ref={ref}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClick={e => {
          e.stopPropagation()
        }}
        onClose={handleClose}
        data-testid={`menu-action-${record.id}`}
      >
        {children}
        {copyListItem}

        <ArchiveListItem resource={resource} record={record} customConfirmationMessage={customConfirmationMessage} />
      </Menu>
    </div>
  )
})

export default ListItemActionsMenu
