import React, { useEffect, useCallback, useRef, useLayoutEffect, useMemo, useReducer, useContext } from 'react'
import { Identifier, ListProps, Record } from 'react-admin'

import { defaultInitialState, customListReducer, CustomListAction } from './customListReducer'
import { unionBy } from 'utils/arrays'

const useSafeDispatch = dispatch => {
  const mounted = useRef(false)
  useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return useCallback((...args) => (mounted.current ? dispatch(...args) : undefined), [dispatch])
}

const CustomListContext = React.createContext(defaultInitialState)
CustomListContext.displayName = 'CustomListContext'

type CustomListProviderProps = {
  children: React.ReactNode[] | React.ReactNode
  setSelectedRecordsCallback?: React.Dispatch<React.SetStateAction<Record[]>>
  selectedRecords?: any[]
}

const CustomListProvider: React.FC<CustomListProviderProps & ListProps> = props => {
  const { selectedRecords: preSelectedRecords, setSelectedRecordsCallback, ...rest } = props

  const initialStateRef = useRef(defaultInitialState)
  const [{ selectedRecords, sessionData, pageData, currentSort, selectedIds, sortFunction }, setState] = useReducer(
    customListReducer,
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch(setState)

  const setListContext = useCallback(
    (type: CustomListAction, payload: any) => {
      safeSetState({ type, payload })
    },
    [safeSetState],
  )

  const getRecord = useCallback((id: Identifier) => sessionData[id], [sessionData])

  const unSelectAll = useCallback(() => setListContext(CustomListAction.setSelectedRecords, []), [setListContext])

  const selectAll = useCallback(
    (records: any[]) => {
      const uniqueSessionItems = unionBy(selectedRecords, records, record => record.id)
      setListContext(CustomListAction.setSelectedRecords, uniqueSessionItems)
    },
    [selectedRecords, setListContext],
  )

  const selectRecord = React.useCallback(
    (id: Identifier) => {
      const records = selectedRecords.slice()
      if (id in sessionData) {
        records.push(sessionData[id as Identifier])
      }
      setListContext(CustomListAction.setSelectedRecords, records)
    },
    [selectedRecords, sessionData, setListContext],
  )

  const unSelectRecord = useCallback(
    (id: Identifier) => {
      const items = selectedRecords.filter((item: any) => item.id !== id)
      setListContext(CustomListAction.setSelectedRecords, items)
    },
    [selectedRecords, setListContext],
  )

  useEffect(() => {
    if (setSelectedRecordsCallback) {
      setSelectedRecordsCallback(selectedRecords)
    }
  }, [selectedRecords, setSelectedRecordsCallback])

  useEffect(() => {
    if (preSelectedRecords) {
      setListContext(CustomListAction.setSelectedRecords, preSelectedRecords)
    }
  }, [preSelectedRecords, setListContext])

  const value = useMemo(
    () => ({
      sessionData,
      selectedRecords,
      pageData,
      selectedIds,
      sortFunction,
      currentSort,
      unSelectAll,
      selectAll,
      getRecord,
      selectRecord,
      unSelectRecord,
      setListContext,
    }),
    [
      sessionData,
      selectedRecords,
      pageData,
      selectedIds,
      sortFunction,
      currentSort,
      unSelectAll,
      selectAll,
      getRecord,
      selectRecord,
      unSelectRecord,
      setListContext,
    ],
  )

  return <CustomListContext.Provider value={value} {...rest} />
}

const useCustomListContext = () => {
  const context = useContext(CustomListContext)
  if (!context) {
    throw new Error(`useCustomListContext must be used within a CustomListProvider`)
  }
  return context
}

export { CustomListProvider, useCustomListContext, CustomListContext }
