import React, { ReactElement, ReactNode, cloneElement } from 'react'
import {
  Button as MuiButton,
  Tooltip,
  IconButton,
  useMediaQuery,
  PropTypes as MuiPropTypes,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'

import classnames from 'classnames'
import { useTranslate } from 'ra-core'
import { omit } from 'utils/objects'

const useStyles = makeStyles(
  (theme: Theme) => ({
    smallIcon: theme.icons.smallIcon,
    mediumIcon: theme.icons.mediumIcon,
    largeIcon: theme.icons.largeIcon,
  }),
  { name: 'RaButton' },
)

const ButtonXSMall = ({ children, label, disabled, className, color, ...props }) => {
  const translate = useTranslate()

  return label && !disabled ? (
    <Tooltip title={translate(label, { _: label })}>
      <IconButton aria-label={translate(label, { _: label })} className={className} color={color} {...props}>
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton className={className} color={color} disabled={disabled} {...props}>
      {children}
    </IconButton>
  )
}

interface Props {
  alignIcon?: 'left' | 'right'
  children?: ReactElement
  classes?: object
  className?: string
  color?: MuiPropTypes.Color
  component?: ReactNode
  label?: string
  icon?: ReactElement
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  saving?: boolean
}

type ButtonProps = Props & MuiButtonProps

const sanitizeButtonRestProps = props => {
  return omit(props, [
    'basePath',
    'handleSubmit',
    'handleSubmitWithRedirect',
    'invalid',
    'onSave',
    'pristine',
    'record',
    'redirect',
    'resource',
    'saving',
    'submitOnEnter',
    'undoable',
  ])
}

const Button: React.FC<ButtonProps> = props => {
  const {
    children,
    className,
    disabled,
    label,
    classes: classesOverride,
    color = 'primary',
    alignIcon = 'left',
    size = 'small',
    ...rest
  } = props
  const translate = useTranslate()
  const classes = useStyles(props)
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const restProps = sanitizeButtonRestProps(rest)

  return isXSmall ? (
    <ButtonXSMall label={label} disabled={disabled} className={className} color={color} {...restProps}>
      {children}
    </ButtonXSMall>
  ) : (
    <MuiButton
      className={className}
      color={color}
      size={size}
      aria-label={label ? translate(label, { _: label }) : undefined}
      disabled={disabled}
      {...restProps}
    >
      {alignIcon === 'left' &&
        children &&
        cloneElement(children, {
          className: classnames(classes[`${size}Icon`]),
        })}
      {label && <span className={className}>{translate(label, { _: label })}</span>}
      {alignIcon === 'right' &&
        children &&
        cloneElement(children, {
          className: classes[`${size}Icon`],
        })}
      {!alignIcon && children}
    </MuiButton>
  )
}

export { Button, sanitizeButtonRestProps }
export type { ButtonProps }
