import * as React from 'react'
import { List, Datagrid, TextField, DateField, ListProps } from 'react-admin'

import StudentShow from './StudentShow'
import { DEFAULT_LIMIT } from 'utils/constants'

const StudentList: React.FC<ListProps> = props => {
  return (
    <List {...props} perPage={DEFAULT_LIMIT} sort={{ field: 'date', order: 'desc' }}>
      <Datagrid expand={<StudentShow />}>
        <TextField source="data.email" />
        <TextField source="form" />
        <TextField source="state" />
        <DateField source="created" />
        <DateField source="modified" />
      </Datagrid>
    </List>
  )
}

export default StudentList
