import React, { Children, cloneElement } from 'react'
import { TextField, DateField, CreateButton, TopToolbar, EditButton, FilterButton } from 'react-admin'
import { makeStyles } from '@material-ui/styles'

import TermsField from 'pages/classes/ClassList/TermsField'
import ClassItemActionMenu from './ClassActionMenu'
import { CustomList } from 'components/CustomList'
import ClassBulkActionButtons from '../ClassBulkEdit/ClassBulkActionButtons'
import { CustomDatagrid } from 'components/CustomDatagrid'

import { classFilters } from './ClassFilters'
import { CustomExportButton } from 'components/CustomExportButton'
import { exportClasses } from 'utils/classes'

const useStyles = makeStyles({
  editButton: {
    '& span': {
      color: '#BCB5B9',
    },
    '& span .RaButton-label-7': {
      display: 'none',
    },
  },
  filters: {
    '& > div:first-child': {
      alignItems: 'center',
    },

    '& form': {
      padding: '15px 0',
      width: '80%',
      '& div': {
        maxWidth: '630px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
      },
      '& .filter-field > div:last-child': {
        display: 'none',
      },
    },
    '& .search-query-input': {
      maxWidth: '555px',
    },
  },
  title: {
    wordBreak: 'break-word',
  },
  toolbar: {
    display: 'flex',
  },
  topToolbar: {
    padding: '0',
    minWidth: '350px',
    justifyContent: 'space-between',
  },
})

type ClassEditButtonProps = { record?: any }
const ClassEditButton: React.FC<ClassEditButtonProps> = ({ record }) => {
  const classes = useStyles()
  return <EditButton basePath="/classes" className={classes.editButton} label="Edit" record={record} />
}

const ListActions: React.FC = () => {
  const classes = useStyles()

  return (
    <TopToolbar className={classes.topToolbar}>
      <FilterButton />
      <CustomExportButton resource="classes" exporter={exportClasses} />
      <CreateButton variant="contained" color="primary" />
    </TopToolbar>
  )
}

const ListActionToolbar = ({ children, ...props }) => {
  const classes = useStyles()
  return <div className={classes.toolbar}>{Children.map(children, button => cloneElement(button, props))}</div>
}

const ClassList: React.FC = props => {
  const classes = useStyles(props)
  return (
    <CustomList
      {...props}
      className={classes.filters}
      filters={classFilters}
      actions={<ListActions />}
      bulkActionButtons={<ClassBulkActionButtons />}
    >
      <CustomDatagrid resource="classes" {...props}>
        <TextField label="Class Title" source="title" className={classes.title} />
        <TermsField label="Terms" source="terms" />
        <TextField label="Host University" source="host_institution.name" />
        <TextField label="Status" source="status" />
        <DateField label="Created Date" source="created_at" />
        <DateField label="Last Updated" source="updated_at" />
        <TextField label="Last Updated By" source="updated_by" />
        <ListActionToolbar label="Actions">
          <ClassEditButton />
          <ClassItemActionMenu {...props} />
        </ListActionToolbar>
      </CustomDatagrid>
    </CustomList>
  )
}

export default ClassList
