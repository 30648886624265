import React from 'react'
import { DatagridBody, DatagridBodyProps } from 'react-admin'
import CustomDatagridRow from './CustomDatagridRow'

type CustomDatagridBodyProps = {
  showSelectCheckboxes?: boolean
}

export const CustomDatagridBody: React.FC<CustomDatagridBodyProps & DatagridBodyProps> = ({
  rowClick,
  showSelectCheckboxes,
  ...props
}) => {
  return (
    <DatagridBody
      {...props}
      rowClick={rowClick}
      row={<CustomDatagridRow rowClick={rowClick} showSelectCheckboxes={showSelectCheckboxes} />}
    />
  )
}

export default CustomDatagridBody
