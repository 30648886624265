import React, { useEffect, useState } from 'react'
import {
  TextInput,
  SelectInput,
  SelectInputProps,
  AutocompleteInput,
  AutocompleteInputProps,
  useDataProvider,
  Record,
} from 'react-admin'
import SearchQueryFilter from 'components/SearchQueryFilter'
import { languageOfInstructionChoices, statusChoices } from 'pages/classes/ClassBulkEdit/classValidation'

const UPPER_LIMIT = 3000

interface FilterAutocompleteInputType extends AutocompleteInputProps {
  dataResource: string
}

const fixedChoices = [
  {
    label: 'Language of instruction',
    source: 'language_of_instruction',
    choices: languageOfInstructionChoices,
  },
  {
    label: 'Status',
    source: 'status',
    choices: statusChoices,
  },
]

const FilterSelectInput: React.FC<SelectInputProps> = ({ label, source, choices }) => (
  <SelectInput label={label} source={source} optionText="name" optionValue="id" choices={choices} />
)

const FilterAutocompleteInput: React.FC<FilterAutocompleteInputType> = ({ label, source, dataResource }) => {
  const dataProvider = useDataProvider()
  const [choices, setChoices] = useState<Record[]>([])
  const isCipCode = dataResource === 'cip_codes'
  const placeholder = isCipCode ? `${label} (type 2 letters)` : label
  const feildOptionText = isCipCode ? 'cip_title' : 'name'
  const optionText = isCipCode ? 'cip_title' : 'name'

  useEffect(() => {
    dataProvider
      .getList(dataResource, {
        pagination: { page: 1, perPage: UPPER_LIMIT },
        sort: {
          field: feildOptionText,
          order: 'ASC',
        },
        filter: {},
      })
      .then(({ data }) => {
        setChoices(data)
      })
  }, [dataProvider, dataResource, isCipCode, feildOptionText])

  return (
    <AutocompleteInput
      optionText={optionText}
      label={placeholder}
      source={source}
      choices={choices || []}
      resettable
      shouldRenderSuggestions={val => (isCipCode ? val.trim().length > 1 : true)}
    />
  )
}

const classFilters = [
  <SearchQueryFilter key="search-query-filter" className="search-query-input" source="Search" alwaysOn />,
  <TextInput key="search-city-filter" label="City" source="host_institution__shipping_address_city" />,
  <FilterAutocompleteInput
    key="search-host-institution-country-id-filter"
    label="Country"
    source="host_institution__country__id"
    dataResource="countries"
  />,
  <FilterAutocompleteInput key="search-terms-filter" label="Terms" source="terms_ids" dataResource="terms" />,
  <FilterAutocompleteInput
    key="search-host-institution-filter"
    label="Host Institutions"
    source="host_institution"
    dataResource="host_institutions"
  />,
  <FilterAutocompleteInput
    key="search-cip-code-filter"
    label="Area of study"
    source="cip_code"
    dataResource="cip_codes"
  />,
  ...fixedChoices.map(({ label, source, choices }) => (
    <FilterSelectInput key={`search-${source}-filter`} label={label} source={source} choices={choices} />
  )),
]

export { classFilters }
