import React, { useEffect, useState, useCallback } from 'react'
import { DatagridHeaderProps, useDatagridStyles, DatagridHeaderCell, useTranslate } from 'react-admin'
import { TableRow, TableCell, Checkbox, TableHead } from '@material-ui/core'
import { useCustomListContext } from '../CustomList'
import classnames from 'classnames'

interface CustomDatagridHeaderProps extends DatagridHeaderProps {
  children?: React.ReactNode
  resource: string
  showSelectCheckboxes?: boolean
}

const CustomDatagridHeader: React.FC<CustomDatagridHeaderProps> = props => {
  const { children, className, data, hasExpand, resource, showSelectCheckboxes = true } = props
  const { selectedRecords, unSelectAll, selectAll, currentSort, sortFunction } = useCustomListContext()

  const classes = useDatagridStyles()
  const [checked, setChecked] = useState<boolean>(false)
  const [currentPageItems, setCurrentPageItems] = useState<any[]>([])
  const translate = useTranslate()

  const updateSortCallback = useCallback(
    event => {
      event.stopPropagation()
      const newField = event.currentTarget.dataset.field
      const newOrder =
        currentSort.field === newField
          ? currentSort.order === 'ASC'
            ? 'DESC'
            : 'ASC'
          : event.currentTarget.dataset.order

      sortFunction(newField, newOrder)
    },
    [currentSort.field, currentSort.order, sortFunction],
  )

  const onClick = () => {
    if (checked) {
      unSelectAll()
    } else {
      selectAll(currentPageItems)
    }
    setChecked(!checked)
  }

  const isSelectAllChecked = useCallback(() => {
    let result = true
    currentPageItems.forEach(cItem => {
      const item = selectedRecords.find(sItem => sItem.id === cItem.id)
      if (!item) {
        result = false
      }
    })
    return result
  }, [currentPageItems, selectedRecords])

  useEffect(() => {
    if (data) {
      const items = Object.keys(data).map(key => data[key])
      setCurrentPageItems(items)
    }
  }, [data])

  useEffect(() => {
    if (Array.isArray(selectedRecords)) {
      if (isSelectAllChecked()) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
  }, [isSelectAllChecked, selectedRecords])

  return (
    <TableHead className={classnames(className, classes.thead)}>
      <TableRow className={classnames(classes.row, classes.headerRow)}>
        {hasExpand && <TableCell padding="none" className={classnames(classes.headerCell, classes.expandHeader)} />}
        {showSelectCheckboxes && (
          <TableCell padding="checkbox" className={classes.headerCell}>
            <Checkbox
              aria-label={translate('ra.action.select_all', {
                _: 'Select all',
              })}
              className="select-all"
              color="primary"
              checked={checked}
              onChange={onClick}
              data-testid="datagridSelectAllCheckbox"
            />
          </TableCell>
        )}
        {React.Children.map(children, (field, index) =>
          React.isValidElement(field) ? (
            <DatagridHeaderCell
              className={classes.headerCell}
              currentSort={currentSort}
              field={field}
              isSorting={currentSort.field === ((field.props as any).sortBy || (field.props as any).source)}
              key={(field.props as any).source || index}
              resource={resource}
              updateSort={updateSortCallback}
            />
          ) : null,
        )}
      </TableRow>
    </TableHead>
  )
}

export default CustomDatagridHeader
