import * as React from 'react'
import { Create } from 'react-admin'
import ProgramForm from '../ProgramForm'

const ProgramCreate: React.FC = props => {
  const transform = (data: any) => {
    return {
      ...data,
    }
  }

  return (
    <Create {...props} title="Create Program" component="div" transform={transform}>
      <ProgramForm />
    </Create>
  )
}

export default ProgramCreate
