import { CreditUnitFilters, HostInstitutionFilters, ProgramFilters } from '../types'

const prepFilters = (filters: CreditUnitFilters | HostInstitutionFilters | ProgramFilters) => {
  const newFilters = { ...filters }
  const deleteFilters = Object.keys(newFilters)
  deleteFilters.forEach(filter => {
    type filterKey = keyof typeof newFilters
    if (!newFilters[filter as unknown as filterKey]) {
      delete newFilters[filter as unknown as filterKey]
    }
  })

  return newFilters
}

export { prepFilters }
