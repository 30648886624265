import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { useForm } from 'react-final-form'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useQuery, AutocompleteInputProps, Record } from 'react-admin'

import { buildQueryParams } from 'utils/queryParams'
import { DEFAULT_LIMIT, DEFAULT_SORT_ORDER } from 'utils/constants'
import { arrayIsNotEmpty } from 'utils/arrays'

interface CustomAutocompleteProps extends AutocompleteInputProps {
  source?: string
  perPage?: number
  sortField?: string
  sortOrder?: string
  multiple?: boolean
  optionId?: string
  optionLabel?: string
  selectedOptions?: any[]
  initialOptions?: any[]
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
  id,
  label,
  resource,
  className,
  perPage = DEFAULT_LIMIT,
  sortField,
  sortOrder = DEFAULT_SORT_ORDER,
  multiple = false,
  optionId = 'id',
  optionLabel = 'name',
  selectedOptions = [],
  initialOptions = [],
}) => {
  const [value, setValue] = React.useState<any[]>([])
  const [options, setOptions] = React.useState<any[]>([])
  const [inputValue, setInputValue] = React.useState<string>('')
  const { change } = useForm()

  const handleChange = (inputValue: Record[]) => {
    setValue(inputValue)
    change(resource, inputValue)
  }

  const { data = [], loading } = useQuery({
    type: 'getList',
    resource,
    payload: buildQueryParams({ perPage, sortField, sortOrder, filter: { q: inputValue } }),
  })

  useEffect(() => {
    // If initial selectedOptions are passed in
    // update the value of the input
    if (arrayIsNotEmpty(selectedOptions)) {
      setValue(selectedOptions)
    }
  }, [selectedOptions])

  useEffect(() => {
    // If the value is present the Autocomplete will check the
    // current available options (data) for a match with the value
    // So we need to add the value options to the current list of available options
    if (arrayIsNotEmpty(data)) {
      const newOptions = [...data.slice(), ...value]
      setOptions(newOptions)
    }
  }, [data, value])

  useEffect(() => {
    if (arrayIsNotEmpty(initialOptions)) {
      setValue(initialOptions)
    }
  }, [initialOptions])

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      loading={loading}
      loadingText={<CircularProgress size="1rem" />}
      className={className}
      getOptionLabel={option => (typeof option === 'string' ? option : option[optionLabel])}
      getOptionSelected={(option, value) => option[optionId] === value[optionId]}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_, newInputValue: Record[]) => handleChange(newInputValue)}
      onInputChange={(_, newInputValue: string) => setInputValue(newInputValue)}
      renderInput={(params: any) => <TextField variant="filled" {...params} label={label} />}
      renderOption={(option: any) => {
        return (
          <span
            key={option[optionId]}
            style={{
              fontWeight: 400,
            }}
          >
            {option[optionLabel]}
          </span>
        )
      }}
    />
  )
}

export default CustomAutocomplete
