import { BulkArchiveButton, BulkEditButton } from 'components/ListActions'
import ClassBulkEdit from '../ClassBulkEdit'

const ClassBulkActionButtons: React.FC = props => {
  return (
    <>
      <BulkArchiveButton resource="classes" {...props} />
      <BulkEditButton {...props}>
        <ClassBulkEdit {...props} />
      </BulkEditButton>
    </>
  )
}

export default ClassBulkActionButtons
