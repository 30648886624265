import * as React from 'react'
import { useUpdateMany, useRefresh, useNotify, useUnselectAll } from 'react-admin'
import { Form } from 'react-final-form'

import ClassBulkEditForm from './ClassBulkEditForm'
import { Identifier } from 'ra-core'

const defaultFormData = {
  formField: 'status',
  formValue: 'active',
  formLabel: 'Status',
}

type ClassBulkEditProps = {
  selectedIds?: Identifier[]
  resource?: string
  handleCloseClick?: () => void
}
const ClassBulkEdit: React.FC<ClassBulkEditProps> = ({ selectedIds, resource, handleCloseClick }) => {
  const [updateMany] = useUpdateMany()

  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const handleSubmit = async (values: any) => {
    const { formField, formValue } = values

    updateMany(
      resource,
      selectedIds,
      { field: formField, value: formValue },
      {
        onSuccess: ({ data, errors }: any) => {
          handleCloseClick && handleCloseClick()
          refresh()

          if (errors) {
            errors.forEach((err: any) => {
              if (err?.status_code && err?.status_code === 404) {
                notify(err.detail, { type: 'warning' })
              } else {
                notify(JSON.stringify(err), { type: 'warning' })
              }
            })
          }

          if (Array.isArray(data) && data.length > 0) {
            notify(`${resource} updated`)
          }

          unselectAll(resource)
        },
        onFailure: error => notify(`Error: ${resource} not updated`, { type: 'warning' }),
      },
    )
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={defaultFormData}
      render={() => {
        return <ClassBulkEditForm handleCloseClick={handleCloseClick} onSubmit={handleSubmit} />
      }}
    />
  )
}

export default ClassBulkEdit
