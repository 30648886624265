import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
  DataProvider as ReactAdminDataProvider,
} from 'react-admin'

import StudentProvider from './StudentProvider'
import CountryProvider from './CountryProvider'
import HostInstitutionProvider from './HostInstitutionProvider'
import ProgramProvider from './ProgramProvider'
import CreditUnitProvider from './CreditUnitProvider'
import SourceSystemProvider from './SourceSystemProvider'
import ClassProvider from './ClassProvider'
import TermProvider from './TermProvider'
import CIPCodeProvider from './CIPCodeProvider'
import EducationLevelProvider from './EducationLevelProvider'

import { LEARNING_SERVICE_ENDPOINT, ADMIN_SERVICE_ENDPOINT } from 'utils/constants'
import { GetManyParamsWithPages } from 'types'

const BASE_URL = 'baseUrl'

interface Providers {
  students: StudentProvider
  countries: CountryProvider
  host_institutions: HostInstitutionProvider
  programs: ProgramProvider
  classes: ClassProvider
  terms: TermProvider
  cip_codes: CIPCodeProvider
  credit_units: CreditUnitProvider
  source_systems: SourceSystemProvider
  education_levels: EducationLevelProvider
}

const notBaseUrl = (key: string) => key !== BASE_URL

class DataProvider {
  public learningServiceEndpoint: string
  public adminServiceEndpoint: string

  constructor(dataProviders: Providers) {
    this.learningServiceEndpoint = LEARNING_SERVICE_ENDPOINT
    this.adminServiceEndpoint = ADMIN_SERVICE_ENDPOINT
    this._mapDataProviders(dataProviders)
  }

  _mapDataProviders(dataProviders: Providers) {
    Object.keys(dataProviders).forEach((key: string) => {
      this[key] = dataProviders[key]
      this._mapDelegates(dataProviders[key])
    })
  }

  _mapDelegates(dataProvider: ReactAdminDataProvider) {
    Object.keys(dataProvider)
      .filter(key => notBaseUrl(key))
      .map((key: string) => (this[key] = (resource: string, params: any) => this._delegate(key, resource, params)))
  }

  _delegate(name: string, resource: string, params: any) {
    return this[resource][name](resource, params)
  }

  getList(resource: string, params: GetListParams) {
    return this._delegate('getList', resource, params)
  }

  getOne(resource: string, params: GetOneParams) {
    return this._delegate('getOne', resource, params)
  }

  getMany(resource: string, params: GetManyParamsWithPages) {
    return this._delegate('getMany', resource, params)
  }

  getManyReference(resource: string, params: GetManyReferenceParams) {
    return this._delegate('getManyReference', resource, params)
  }

  create(resource: string, params: CreateParams) {
    return this._delegate('create', resource, params)
  }

  update(resource: string, params: UpdateParams) {
    return this._delegate('update', resource, params)
  }

  updateMany(resource: string, params: UpdateManyParams) {
    return this._delegate('updateMany', resource, params)
  }

  delete(resource: string, params: DeleteParams) {
    return this._delegate('delete', resource, params)
  }

  deleteMany(resource: string, params: DeleteManyParams) {
    return this._delegate('deleteMany', resource, params)
  }
}

export default DataProvider
