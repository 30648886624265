import React, { Fragment, useState } from 'react'
import { Button } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiPaper-root': {
      padding: '35px !important',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
  },
}))

type BulkEditButtonProps = {
  children: React.ReactNode
}

const BulkEditButton: React.FC<BulkEditButtonProps> = ({ children, ...props }) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const label = 'Bulk Edit'
  return (
    <Fragment>
      <Button startIcon={<EditIcon />} label={label} onClick={handleClick} />
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={label}
        className={classes.dialog}
      >
        <DialogTitle className={classes.title}>{label}</DialogTitle>
        {children &&
          React.cloneElement(children as React.ReactElement<any>, {
            handleCloseClick,
            ...props,
          })}
      </Dialog>
    </Fragment>
  )
}

export default BulkEditButton
