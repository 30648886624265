import * as React from 'react'
import { Create } from 'react-admin'
import ClassForm from '../ClassForm'

import { filterRelationalData } from 'utils/classes'

const ClassCreate: React.FC = props => {
  const transform = (data: any) => {
    return filterRelationalData(data)
  }

  return (
    <Create {...props} title="Create Class" component="div" transform={transform}>
      <ClassForm hideSyllabus />
    </Create>
  )
}

export default ClassCreate
