import { DEFAULT_LIMIT } from 'utils/constants'
import { Identifier } from 'react-admin'
import dataProvider from 'providers'

const LIMIT_PARALLEL_REQUESTS = 3

async function getManyWithoutPagination(resource: string, ids: Array<Identifier>) {
  const limit = DEFAULT_LIMIT
  if (ids.length > 0) {
    const skips = Array.from(Array(Math.ceil(ids.length / limit)).keys())
    const callback = skip => dataProvider.getMany(resource, { ids, skip, limit }).then(value => value.data)

    const res: Array<any> = []

    for (let index = 0; index < skips.length; index += 1) {
      const chunk = skips.splice(index, index + LIMIT_PARALLEL_REQUESTS).map(value => callback(value * limit))
      // eslint-disable-next-line no-await-in-loop
      res.push(...(await Promise.all(chunk)))
    }

    return { data: res.flat() }
  }
  return { data: [] }
}

export { getManyWithoutPagination }
