import { Record, useResourceContext } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useCustomListContext } from '../CustomList'

import { ClassIcon } from '../Icons'

type ArchiveConfirmationMessageProps = {
  record?: Record
}

const ArchiveConfirmationMessage: React.FC<ArchiveConfirmationMessageProps> = ({ record }) => {
  const resource = useResourceContext()
  const { selectedRecords = [] } = useCustomListContext()

  const items = record ? [record] : selectedRecords
  return (
    <>
      <Typography data-test="modal-confirm-message-archive">{`Are you sure to archive the following ${resource}?`}</Typography>
      <List>
        {items.map((item: any) => {
          return (
            <ListItem key={item.id} id={item.id} resource={resource}>
              <ListItemIcon>
                <ClassIcon />
              </ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

export default ArchiveConfirmationMessage
