import React, { useEffect, useState, useCallback } from 'react'
import { Identifier, Record } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import ProgramFieldDisplay from './ProgramFieldDisplay'
import { Program } from 'types'
import { CancelButton, SaveButton } from 'components/Buttons'
import ProgramList from './ProgramList'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  dialogRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px',
    borderRadius: '5px',
  },
  button: {
    padding: '6px 26px',
  },
}))

type ProgramSelectorProps = {
  programs: Program[]
}

const ProgramSelector: React.FC<ProgramSelectorProps> = ({ programs = [], ...props }) => {
  const classes = useStyles(props)
  const [showDialog, setShowDialog] = useState(false)
  const [selectedPrograms, setSelectedPrograms] = useState<Record[]>([])
  const [sessionSelectedPrograms, setSessionSelectedPrograms] = useState<Record[]>([])

  const form = useForm()

  const onSave = () => {
    setSelectedPrograms(sessionSelectedPrograms)
    setShowDialog(false)
  }

  const onCancel = () => {
    setShowDialog(false)
    setSessionSelectedPrograms([])
  }

  const removeItem = (id: Identifier) => {
    const items = selectedPrograms.filter((item: Record) => item.id !== id)
    setSelectedPrograms(items)
  }

  const updateForm = useCallback(
    (input: Record[]) => {
      const ids = input.map((item: Record) => item?.id)
      form.change('programs', ids)
    },
    [form],
  )

  useEffect(() => {
    if (selectedPrograms) {
      updateForm(selectedPrograms)
    }
  }, [selectedPrograms, updateForm])

  useEffect(() => {
    if (programs) {
      setSelectedPrograms(programs)
    }
  }, [programs, setSelectedPrograms])

  return (
    <div className={classes.root}>
      <ProgramFieldDisplay
        setShowDialog={setShowDialog}
        selectedPrograms={selectedPrograms}
        removeProgram={removeItem}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={showDialog}
        aria-label={'Select Programs'}
        data-testid="listSelectorDialog"
      >
        <DialogTitle style={{ textAlign: 'center' }}>{'Program Selector'}</DialogTitle>
        <DialogContent>
          <ProgramList selectedPrograms={selectedPrograms} setSessionSelectedPrograms={setSessionSelectedPrograms} />
        </DialogContent>
        <DialogActions className={classes.dialogRoot}>
          <SaveButton className={classes.button} onClick={onSave} dataTestId="listSelectorDialogSave" />
          <CancelButton className={classes.button} onClick={onCancel} dataTestId="listSelectorDialogCancel" />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ProgramSelector
