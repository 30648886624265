import * as React from 'react'
import { TextInput, TextInputProps } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import { Grid, GridSize } from '@material-ui/core'

const useStyles = makeStyles({
  gridItem: {
    width: '100%',
    padding: '0',
  },
})

export type GridTextInputProps = {
  xs?: boolean | GridSize
  md?: boolean | GridSize
  testId: string
  validate?: any
}

const GridTextInput: React.FC<GridTextInputProps & TextInputProps> = ({
  xs = 12,
  md = 6,
  source,
  label,
  testId,
  placeholder = 'Enter',
  fullWidth = true,
  validate,
}) => {
  const classes = useStyles()
  return (
    <Grid item className={classes.gridItem} xs={xs} md={md}>
      <TextInput
        source={source}
        placeholder={placeholder}
        label={label}
        fullWidth={fullWidth}
        validate={validate}
        data-testid={testId}
      />
    </Grid>
  )
}

export default GridTextInput
