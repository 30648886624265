import * as React from 'react'
import { Layout, AppBar, Menu, LayoutProps } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import RACustomError from '../RACustomError'

import LogoutButton from '../LogoutButton'
import {
  StudentsMenuItem,
  CountriesMenuItem,
  HostInstitutionsMenuItem,
  ProgramsMenuItem,
  ClassesMenuItem,
} from './MenuItems'
import Logo from '../Logo'

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#fff',
    color: '#3a3b3f',
    borderBottom: '1px solid #eee',
    boxShadow: 'none',
  },
  menu: {
    borderRight: '1px solid #eee',
  },
})

const CustomAppBar: React.FC = props => {
  const classes = useStyles()
  return (
    <AppBar className={classes.appBar} {...props} logout={React.createElement(LogoutButton)}>
      <Typography variant="h6" color="inherit" id="react-admin-title" />
      <Logo />
    </AppBar>
  )
}

const CustomMenu: React.FC = props => {
  const classes = useStyles()
  return (
    <Menu className={classes.menu} {...props}>
      <StudentsMenuItem {...props} />
      <CountriesMenuItem {...props} />
      <HostInstitutionsMenuItem {...props} />
      <ProgramsMenuItem {...props} />
      <ClassesMenuItem {...props} />
    </Menu>
  )
}

const CustomLayout: React.FC<LayoutProps> = props => {
  return <Layout {...props} appBar={() => <CustomAppBar />} menu={() => <CustomMenu />} error={RACustomError} />
}

export default CustomLayout
