import { stringify } from 'query-string'
import { GetListParams, GetOneParams } from 'react-admin'
import axios from 'axios'

import { ADMIN_SERVICE_ENDPOINT } from 'utils/constants'

class StudentProvider {
  public baseUrl: string

  public constructor() {
    this.baseUrl = ADMIN_SERVICE_ENDPOINT
  }

  public getList = async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const query = {
      skip: (page - 1) * perPage,
      limit: perPage,
      sort: `${order === 'ASC' ? '' : '-'}${field}`,
    }

    const url = `${this.baseUrl}/${resource}/?${stringify(query)}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }

  public getOne = async (resource: string, params: GetOneParams) => {
    const url = `${this.baseUrl}/${resource}/${params.id}`

    return axios
      .get(url)
      .then(response => {
        const { data, count: total } = response.data

        if (!data) {
          Promise.reject()
        }

        return Promise.resolve({
          data,
          total,
        })
      })
      .catch(err => Promise.reject(err))
  }
}

export default StudentProvider
