import * as React from 'react'
import red from '@material-ui/core/colors/red'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    status: {
      danger: string
    }
    icons: {
      xSmallIcon: {
        fontSize: number
      }
      smallIcon: {
        fontSize: number
      }
      mediumIcon: {
        fontSize: number
      }
      largeIcon: {
        fontSize: number
      }
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
    icons: {
      xSmallIcon: {
        fontSize: number
      }
      smallIcon: {
        fontSize: number
      }
      mediumIcon: {
        fontSize: number
      }
      largeIcon: {
        fontSize: number
      }
    }
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#099099',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: { main: '#fff' },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  icons: {
    xSmallIcon: {
      fontSize: 18,
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  },
})

export const provideTheme = (ui: React.ReactElement): React.ReactElement => {
  return <ThemeProvider theme={theme}>{ui}</ThemeProvider>
}
