import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { useListContext, Record } from 'react-admin'
import { Class } from 'types'

interface CopyListItemActionProps {
  onCopyItem?: React.MouseEventHandler<HTMLLIElement>
  record: Record
}

const CopyListItemAction = React.forwardRef<HTMLInputElement, CopyListItemActionProps>(
  ({ onCopyItem, record }, ref) => {
    const { selectedIds } = useListContext<Class>()

    return (
      <MenuItem
        key="copy"
        data-testid={`dropdown-copy-button-${record.id}`}
        onClick={onCopyItem}
        disabled={selectedIds.length > 1}
      >
        <ListItemIcon>
          <FileCopyOutlined fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" noWrap>
          Copy
        </Typography>
      </MenuItem>
    )
  },
)

export default CopyListItemAction
