const CONCURRENT_REQUEST_LIMIT = 1

const fetchRecords = async <Type>(
  records: Type[],
  pageRequests: number[],
  promise: (page: number) => Promise<Type[]>,
) => {
  const promises = pageRequests.map((page: number) => promise(page))
  const results = await Promise.all(promises)

  return [...records, ...results.flat()]
}

const chunkRequests = (counter: number, pages: number): number[] => {
  const requests = Array.from(Array(pages), (x, index) => index + 1)
  return requests.splice(counter - 1, CONCURRENT_REQUEST_LIMIT)
}

export const fetchAllRecords = async <Type>(
  pages: number,
  fetchRecordsPromise: (page: number) => Promise<Type[]>,
  records: Type[] = [],
  counter = 1,
) => {
  if (counter > pages) {
    return records
  }

  const pageRequests: number[] = chunkRequests(counter, pages)
  const data = await fetchRecords(records, pageRequests, fetchRecordsPromise)

  return fetchAllRecords(pages, fetchRecordsPromise, data, counter + CONCURRENT_REQUEST_LIMIT)
}
