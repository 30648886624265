import * as React from 'react'
import { TextField, DateField, CreateButton, TopToolbar, EditButton } from 'react-admin'
import { makeStyles } from '@material-ui/styles'
import SearchQueryFilter from 'components/SearchQueryFilter'
import { CustomList } from 'components/CustomList'
import { CustomDatagrid } from 'components/CustomDatagrid'
import { Children, cloneElement } from 'react'
import ProgramItemActionMenu from './ProgramActionMenu'
import { DEFAULT_LIMIT, DEFAULT_SORT_ORDER, DEFAULT_SORT_FIELD } from 'utils/constants'

const useStyles = makeStyles({
  filters: {
    '& form': {
      alignItems: 'center',
      width: '100%',
      margin: '0 0 24px 0',
      paddingTop: '24px',
      '& div': {
        maxWidth: '630px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
      },
      '& [class*="RaFilterFormInput-spacer"]': {
        display: 'none',
      },
      '& [data-source="name"]': {
        width: '100%',
      },
      '& #city-search-helper-text': {
        display: 'none',
      },
      '& #full-text-search-helper-text': {
        display: 'none',
      },
    },
    '& .search-query-input': {
      maxWidth: '555px',
    },
  },
  editButton: {
    '& span': {
      color: '#BCB5B9',
    },
    '& span .RaButton-label-7': {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
  },
})

type ProgramEditButtonProps = { record?: any }

const ProgramEditButton: React.FC<ProgramEditButtonProps> = ({ record }) => {
  const classes = useStyles()
  return <EditButton basePath="/programs" className={classes.editButton} label="Edit" record={record} />
}

const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton variant="contained" color="primary" />
  </TopToolbar>
)

const ListActionToolbar = ({ children, ...props }) => {
  const classes = useStyles()
  return <div className={classes.toolbar}>{Children.map(children, button => cloneElement(button, props))}</div>
}

const programsFilters = [<SearchQueryFilter key="programs-search-query-filter-key" source="Search" alwaysOn />]

const ProgramList: React.FC = props => {
  const classes = useStyles(props)

  return (
    <CustomList
      {...props}
      className={classes.filters}
      perPage={DEFAULT_LIMIT}
      sort={{ field: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER }}
      actions={<ListActions />}
      filters={programsFilters}
      bulkActionButtons={false}
    >
      <CustomDatagrid resource="programs" {...props}>
        <TextField label="Program Name" source="name" />
        <TextField label="Salesforce ID" source="salesforce_id" />
        <DateField label="Created Date" source="created_at" />
        <DateField label="Last Updated" source="updated_at" />
        <TextField label="Last Updated By" source="updated_by" />
        <ListActionToolbar label="Actions">
          <ProgramEditButton />
          <ProgramItemActionMenu {...props} />
        </ListActionToolbar>
      </CustomDatagrid>
    </CustomList>
  )
}

export default ProgramList
