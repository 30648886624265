import React, { useState, useCallback, useEffect } from 'react'
import { DatagridRowProps, Record, linkToRecord, useTranslate, Identifier } from 'react-admin'
import { TableRow, TableCell, Checkbox } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'

import { useCustomListContext } from '../CustomList'

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))

const defaultClasses = {
  expandIconCell: '',
  checkbox: '',
  rowCell: '',
  tableRow: { cursor: 'pointer' },
  expandedPanel: '',
}

type CustomDatagridRowProps = {
  showSelectCheckboxes?: boolean
}

const CustomDatagridRow: React.FC<CustomDatagridRowProps & DatagridRowProps> = ({
  resource,
  children,
  basePath,
  id,
  record,
  rowClick = 'edit',
  selectable = true,
  hover = true,
  style,
  className,
  showSelectCheckboxes = true,
  classes = defaultClasses,
}) => {
  const classStyles = useStyles()
  const { selectedRecords, selectRecord, unSelectRecord } = useCustomListContext()
  const [checked, setChecked] = useState<boolean>(false)

  const translate = useTranslate()
  const history = useHistory()

  const recordId = id as Identifier

  const handleToggleSelection = useCallback(
    event => {
      if (!selectable) return
      event.stopPropagation()

      if (checked) {
        unSelectRecord(recordId)
      } else {
        selectRecord(recordId)
      }
    },
    [checked, selectable, unSelectRecord, selectRecord, recordId],
  )

  const handleClick = useCallback(
    async event => {
      if (!rowClick) return
      event.persist()

      const effect =
        typeof rowClick === 'function'
          ? await rowClick(recordId, basePath || `/${resource}`, record as Record)
          : rowClick
      switch (effect) {
        case 'edit':
          history.push(linkToRecord(basePath || `/${resource}`, recordId))
          return
        case 'show':
          history.push(linkToRecord(basePath || `/${resource}`, recordId, 'show'))
          return
        case 'toggleSelection':
          handleToggleSelection(event)
          return
        default:
          if (effect) history.push(effect)
          return
      }
    },
    [basePath, history, handleToggleSelection, recordId, record, resource, rowClick],
  )

  useEffect(() => {
    if (selectedRecords) {
      const item = selectedRecords.find((item: Record) => item.id === recordId)
      if (item) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
  }, [recordId, selectedRecords])

  return (
    <TableRow
      onClick={handleClick}
      key={id}
      style={style}
      className={classnames(className, classStyles.root)}
      hover={hover}
      data-testid={`datagridRow-${recordId}`}
    >
      {showSelectCheckboxes && selectable && (
        <TableCell padding="checkbox">
          <Checkbox
            aria-label={translate('ra.action.select_row', {
              _: 'Select this row',
            })}
            color="primary"
            className={`custom-datagrid-row-checkbox select-item ${classes.checkbox}`}
            checked={checked}
            onClick={handleToggleSelection}
            data-testid={`datagridRowCheckbox-${recordId}`}
          />
        </TableCell>
      )}

      {React.Children.map(children, field => {
        const element = field as React.ReactElement<any, string | React.JSXElementConstructor<any>>

        return (
          <TableCell key={`${recordId}-${element?.props?.source}`}>
            {React.isValidElement(field)
              ? React.cloneElement(element, {
                  record,
                  basePath,
                  resource,
                })
              : null}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default CustomDatagridRow
