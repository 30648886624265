import React from 'react'
import { DatagridRowProps, Record } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { stringify } from 'query-string'

import { ListItemActionMenu } from 'components/ListActions'
import { sourceSystem } from '../ClassBulkEdit/classValidation'

const sanitizeNullValues = (item: Record) =>
  Object.fromEntries(Object.entries(item).filter(([_, v]: any) => v != null))

type ClassItemActionMenuProps = {
  record?: Record
}

const ClassItemActionMenu = React.forwardRef<HTMLInputElement, DatagridRowProps & ClassItemActionMenuProps>(
  (props, ref) => {
    const { record } = props
    const history = useHistory()
    const classRecord = record as Record

    const handleOnCopy = () => {
      const newClass = sanitizeNullValues(classRecord)

      delete newClass['id']
      delete newClass['cip_codes']
      delete newClass['programs']
      delete newClass['craft_id']

      const copyClass = {
        ...newClass,
        title: `Copy of ${newClass.title}`,
        source_system: sourceSystem.API_CMS,
        status: 'Draft',
      }

      history.push({
        pathname: '/classes/create',
        search: stringify({
          source: JSON.stringify(copyClass),
        }),
        state: { _scrollToTop: true },
      })
    }
    return <ListItemActionMenu resource="classes" record={classRecord} onCopyItem={handleOnCopy} />
  },
)

export default ClassItemActionMenu
