import React, { Children, useEffect, cloneElement } from 'react'
import { ListProps, useListContext } from 'react-admin'

import { useCustomListContext, CustomListProps } from '../CustomList'
import { CustomListAction } from 'components/CustomList/customListReducer'

/**
 * The CustomDatagridProvider is a wrapper around the RA Datagrid in order to gain
 * access to the useListContext and useCustomListContext and keeps the RA ListCtx in sync
 * with the CustomListCtx
 */
const CustomDatagridProvider: React.FC<CustomListProps & ListProps> = ({ children, ...props }) => {
  const { selectedRecords, setListContext } = useCustomListContext()
  const { onSelect, data, selectedIds, setSort, currentSort } = useListContext()

  useEffect(() => {
    setListContext(CustomListAction.setSortFunction, setSort)
    if (currentSort) {
      setListContext(CustomListAction.setCurrentSort, currentSort)
    }
  }, [currentSort, setListContext, setSort])

  useEffect(() => {
    if (data) {
      setListContext(CustomListAction.setPageData, data)
      setListContext(CustomListAction.setSessionData, data)
    }
  }, [data, setListContext])

  useEffect(() => {
    // Setting the ReactAdmin ListContext
    // in order to keep in sync
    onSelect(selectedRecords.map(record => record?.id))
  }, [onSelect, selectedRecords])

  useEffect(() => {
    setListContext(CustomListAction.setListSelectedIds, selectedIds)
  }, [setListContext, selectedIds])

  const childrenWithProps =
    Children.only(children) &&
    Children.map(children, child => {
      const element = child as React.ReactElement<any, string | React.JSXElementConstructor<any>>
      return cloneElement(element, { ...props, ...child.props })
    })

  return <>{childrenWithProps}</>
}

export { CustomDatagridProvider }
