import React, { useState, useEffect } from 'react'
import { useDataProvider, AutocompleteInputProps, Record, AutocompleteInput } from 'react-admin'

interface FilterAutocompleteInputType extends AutocompleteInputProps {
  dataResource: string
}

const FilterAutocompleteInput: React.FC<FilterAutocompleteInputType> = ({
  label,
  source,
  dataResource,
  field,
  perPage,
  sortOrder,
}) => {
  const dataProvider = useDataProvider()
  const [choices, setChoices] = useState<Record[]>([])
  const fieldOptionText = field ?? 'created_at'
  const optionText = 'name'

  useEffect(() => {
    dataProvider
      .getList(dataResource, {
        pagination: { page: 1, perPage },
        sort: {
          field: fieldOptionText,
          order: sortOrder,
        },
        filter: {},
      })
      .then(({ data }) => {
        setChoices(data)
      })
  }, [dataProvider, dataResource, fieldOptionText, perPage, sortOrder])

  return (
    <AutocompleteInput
      optionText={optionText}
      label={label}
      source={source}
      choices={choices || []}
      resettable
      shouldRenderSuggestions
    />
  )
}

export default FilterAutocompleteInput
