import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  selectInput: {
    height: '48px',
    width: '100%',
    borderRadius: '5px 5px 0 0',
    textTransform: 'capitalize',
  },
}))

type BulkEditSelectProps = {
  id?: string
  name?: string
  label?: string
  onChange?: any
  validate?: any
  value?: unknown
  options: SelectOption[]
}

type SelectOption = {
  value: string
  label: string
  name: string
}

const BulkEditSelect: React.FC<BulkEditSelectProps> = ({ id, name, label, onChange, options, value }) => {
  const classes = useStyles()

  return (
    <Select
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      variant="filled"
      className={classes.selectInput}
    >
      {options.map((item: SelectOption) => (
        <MenuItem key={`${item.name}-${item.value}`} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default BulkEditSelect
