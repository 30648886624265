import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import { useMutation, useNotify, useRefresh, Record } from 'react-admin'
import Confirm from 'components/Confirm'

import ArchiveConfirmationMessage from '../ArchiveConfirmationMessage'
import { useCustomListContext } from 'components/CustomList'

type ArchiveListItemActionProps = {
  resource: string
  record: Record
  customConfirmationMessage?: React.ReactElement
}

const ArchiveListItemAction = React.forwardRef<any, ArchiveListItemActionProps>(
  ({ record, resource, customConfirmationMessage }, ref) => {
    const [isOpenPopup, setOpenPopup] = useState(false)
    const { unSelectAll } = useCustomListContext()
    const notify = useNotify()
    const refresh = useRefresh()
    const recordName = resource === 'classes' ? record.title : record.name

    const [archive, { loading }] = useMutation(
      {
        type: 'archive',
        resource,
        payload: { id: record.id, data: {} },
      },
      {
        onSuccess: data => {
          notify(`Archived: ${recordName}`)
          unSelectAll()
          refresh()
        },
        onFailure: err => {
          notify('Oops! something went wrong')
        },
      },
    )

    const openConfirm = () => {
      unSelectAll()
      setOpenPopup(true)
    }

    return (
      <>
        <MenuItem
          ref={ref}
          key={`archive-${record.id}`}
          onClick={() => {
            openConfirm()
          }}
          disabled={loading}
          data-testid={`bt-action-archive-${record.id}`}
        >
          <ListItemIcon>
            <ArchiveOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Archive
          </Typography>
        </MenuItem>
        <Confirm
          title="Archive Confirmation"
          loading={loading}
          isOpen={isOpenPopup}
          confirm="Save"
          cancel="Cancel"
          content={customConfirmationMessage ?? <ArchiveConfirmationMessage record={record} />}
          onClose={() => {
            setOpenPopup(false)
          }}
          onConfirm={archive}
        />
      </>
    )
  },
)

export default ArchiveListItemAction
