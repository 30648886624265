import { ExtendButtonBase, ButtonTypeMap } from '@material-ui/core'
import { DatagridProps, ListProps } from 'react-admin'

export const omit = (
  object:
    | Record<string, unknown>
    | (ListProps & DatagridProps)
    | ExtendButtonBase<ButtonTypeMap<Record<string, unknown>, 'button'>>,
  keys: string[],
) => {
  const clone = { ...object }
  keys.forEach(key => {
    delete clone[key]
  })
  return clone
}
