import { compile } from 'mathjs'

export const calculateRecommendedUSCreditAmount = (calculation: string, value: number): string => {
  const newZealandCalculation = '3-4'
  if (!value || !calculation) {
    return '0'
  }
  if (calculation === newZealandCalculation) {
    return newZealandCalculation
  }
  const calculus = calculation.replace('$1', String(value))
  const result = compile(calculus).evaluate()
  return Number(result).toFixed(2)
}
