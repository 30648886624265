import { useCallback, useEffect, useState, MouseEventHandler, FC } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useQuery, Loading, Error as ReactAdminError } from 'react-admin'

import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'

import { Grid } from '@material-ui/core'

// Components
import DialogContent from 'components/DialogContent'
import BulkEditSelect from 'components/ListActions/BulkActions/BulkEditSelect'
import BulkEditTextInput from 'components/ListActions/BulkActions/BulkEditTextInput'
import BulkEditAutocompleteSelect from 'components/ListActions/BulkActions/BulkEditAutocompleteSelect'
import { SaveButton, CancelButton } from 'components/Buttons'
import Error from 'components/Errors/Error'
import Condition from 'components/Condition'
import { buildQueryParams } from 'utils/queryParams'
import { DEFAULT_SORT_ORDER } from 'utils/constants'

// Utils
import {
  classStatusSchema,
  classCostSchema,
  classHostInstitutionSchema,
  classCipCodeSchema,
  classTermSchema,
  fieldTypes,
  statusOptions,
} from './classBulkEditSchema'
import { CIPCode, HostInstitution, Term } from 'types'

const useStyles = makeStyles({
  inputBase: {
    width: '100%',
    borderRadius: '10px 10px 0 0',
    textTransform: 'capitalize',
    marginTop: '0px',
  },
  dialogActions: {
    padding: '0 10px',
    margin: '50px 13px 10px 13px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridStyle: {
    width: '100%',
    padding: '0',
  },
  dialogContentStyle: {
    display: 'flex !important',
    justifyContent: 'space-between',
    overflow: 'auto',
    maxHeight: '152px',
  },
})

type ClassBulkEditFormProps = {
  onSubmit: (values: any) => Promise<void>
  handleCloseClick?: MouseEventHandler<HTMLButtonElement>
}
const ClassBulkEditForm: FC<ClassBulkEditFormProps> = ({ handleCloseClick, onSubmit }) => {
  const classes = useStyles()
  const [errors, setErrors] = useState<any[]>([])
  const [validated, setValidated] = useState<boolean>(false)
  const { values } = useFormState()
  const [formValueTouched, setFormValueTouched] = useState<boolean>(false)
  const { change } = useForm()

  const defaultStatusValue = statusOptions[0].value

  const updateFormField = (value: string) => {
    const selected = fieldTypes[value]

    change('formLabel', selected.label)

    switch (value) {
      case 'host_institution':
      case 'cost':
        change('formValue', '')
        break
      case 'terms':
      case 'cip_codes':
        change('formValue', [])
        break
      case 'status':
        change('formValue', defaultStatusValue)
        break
      default:
        break
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target
    change(name, value)

    if (name === 'formField') {
      setValidated(false)
      setFormValueTouched(false)
      updateFormField(value)
    } else {
      setFormValueTouched(true)
    }
  }

  const autocompleteOnChange = (e: any, val: any) => {
    const { value } = val
    const actualVal = value ?? val.map(item => item.value)
    change('formValue', actualVal)
    setFormValueTouched(true)
  }

  const {
    data: hostInstitutions,
    loading: loadingHostInstitutions,
    error: errorHostInstitutions,
  } = useQuery({
    type: 'getList',
    resource: 'host_institutions',
    payload: buildQueryParams({ perPage: 250, sortOrder: DEFAULT_SORT_ORDER, sortField: 'name' }),
  })

  const {
    data: cipCodes,
    loading: loadingCipCodes,
    error: errorCipCodes,
  } = useQuery({
    type: 'getList',
    resource: 'cip_codes',
    payload: buildQueryParams({ perPage: 3000, sortOrder: DEFAULT_SORT_ORDER, sortField: 'cip_title' }),
  })

  const {
    data: terms,
    loading: loadingTerms,
    error: errorTerms,
  } = useQuery({
    type: 'getList',
    resource: 'terms',
    payload: buildQueryParams({ perPage: 250, sortOrder: DEFAULT_SORT_ORDER, sortField: 'name' }),
  })

  const hostInstitutionsOptions = hostInstitutions?.map((hostInstitution: HostInstitution) => ({
    name: 'host_institution',
    value: hostInstitution.id,
    label: hostInstitution.name,
  }))

  const termsOptions = terms?.map((term: Term) => ({
    name: 'terms',
    value: term.id,
    label: term.name,
  }))

  const cipCodesOptions = cipCodes?.map((cipCode: CIPCode) => ({
    name: 'terms',
    value: cipCode.cip_code,
    label: cipCode.cip_title,
  }))

  const fetchedFieldTypes = {
    ...fieldTypes,
    host_institution: {
      ...fieldTypes.host_institution,
      options: hostInstitutionsOptions,
    },
    terms: {
      ...fieldTypes.terms,
      options: termsOptions,
    },
    cip_codes: {
      ...fieldTypes.cip_codes,
      options: cipCodesOptions,
    },
  }

  const { formField, formValue, formLabel } = values
  const formFieldSelectOptions = Object.keys(fetchedFieldTypes).map((key: string) => fetchedFieldTypes[key])

  const filteredFormFieldSelectOptions = formFieldSelectOptions.filter(
    item => item.options?.length > 0 || item.name === 'cost',
  )

  const formValueSelectOptions = fetchedFieldTypes[formField].options

  const validate = useCallback(
    (schema: any) => {
      schema
        .validate({ formField, formValue })
        .then(() => {
          setErrors([])
          setValidated(true)
        })
        .catch((err: any) => {
          const { errors: yupErrors } = err
          if (Array.isArray(yupErrors) && yupErrors.length) {
            setErrors(yupErrors)
            setValidated(false)
          }
        })
    },
    [formField, formValue],
  )

  useEffect(() => {
    if (formValueTouched) {
      switch (formField) {
        case 'cost':
          validate(classCostSchema)
          break
        case 'host_institution':
          validate(classHostInstitutionSchema)
          break
        case 'terms':
          validate(classTermSchema)
          break
        case 'cip_codes':
          validate(classCipCodeSchema)
          break
        default:
          break
      }
    }
    if (formField === 'status') {
      validate(classStatusSchema)
    }
  }, [formValue, formField, validate, formValueTouched])

  if (loadingHostInstitutions || loadingCipCodes || loadingTerms) {
    return <Loading />
  }

  if (errorHostInstitutions || errorCipCodes || errorTerms) {
    const error = errorHostInstitutions || errorCipCodes || errorTerms
    return <ReactAdminError error={error} />
  }

  return (
    <>
      <DialogContent className={classes.dialogContentStyle}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} className={classes.gridStyle}>
            <BulkEditSelect
              id="formField"
              name="formField"
              value={formField}
              options={filteredFormFieldSelectOptions}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={6} md={6} className={classes.gridStyle}>
            <Condition when="formField" is="status">
              <BulkEditSelect
                id="formValue"
                name="formValue"
                value={formValue}
                options={formValueSelectOptions}
                onChange={onChange}
              />
            </Condition>
            <Condition when="formField" is="cost">
              <BulkEditTextInput
                id="formValue"
                name="formValue"
                value={formValue}
                label={formLabel}
                placeholder="$1.50"
                onChange={onChange}
              />
            </Condition>
            <Condition when="formField" is="host_institution">
              <BulkEditAutocompleteSelect
                label="Host Institution"
                options={formValueSelectOptions}
                onChange={autocompleteOnChange}
              />
            </Condition>
            <Condition when="formField" is="terms">
              <BulkEditAutocompleteSelect
                label="Terms"
                multiple
                options={formValueSelectOptions}
                onChange={autocompleteOnChange}
              />
            </Condition>
            <Condition when="formField" is="cip_codes">
              <BulkEditAutocompleteSelect
                label="Area of Study"
                multiple
                options={formValueSelectOptions}
                onChange={autocompleteOnChange}
              />
            </Condition>
          </Grid>
        </Grid>
      </DialogContent>
      <Error errors={errors} />
      <DialogActions className={classes.dialogActions}>
        <SaveButton id="bulkEditFormSubmit" onClick={() => onSubmit(values)} disabled={!validated} />
        <CancelButton id="bulkEditFormCancel" onClick={handleCloseClick} />
      </DialogActions>
    </>
  )
}

export default ClassBulkEditForm
