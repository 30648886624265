import HostInstitutionList from './HostInstitutionList'
import HostInstitutionShow from './HostInstitutionShow'
import { HostInstitutionIcon } from 'components/Icons'

const hostInstitutions = {
  list: HostInstitutionList,
  show: HostInstitutionShow,
  icon: HostInstitutionIcon,
}

export default hostInstitutions
