import { Edit, useMutation, useNotify } from 'react-admin'
import ClassForm from '../ClassForm'
import { useRef } from 'react'

import { filterRelationalData } from 'utils/classes'

const ClassEdit: React.FC = props => {
  const notify = useNotify()
  const initialRecord = useRef({ api_url_syllabus: '' })
  const endpoint = useRef('')
  const file = useRef({})
  const fields = useRef({})
  const buttonLabel = useRef('Save')
  const [uploadMutation] = useMutation()

  const updateEndpoint = endpointArg => {
    endpoint.current = endpointArg
  }

  const updateFile = fileArg => {
    file.current = fileArg
  }

  const updateFields = fieldsArg => {
    fields.current = fieldsArg
  }

  const updateRecord = recordArg => {
    initialRecord.current = recordArg
  }

  const uploadS3 = () => {
    return new Promise((resolve, reject) => {
      uploadMutation(
        {
          type: 'uploadFile',
          resource: 'classes',
          payload: {
            data: {
              url: endpoint.current,
              fields: fields.current,
              file: file.current,
            },
          },
        },
        {
          onSuccess: () => resolve('Syllabus uploaded'),
          onFailure: error => reject(error),
        },
      )
    })
  }

  const transform = async (data: any) => {
    const newData = { ...data }
    delete newData.country
    delete newData.source_system
    delete newData.credit_unit

    // Does it have a file to upload?
    if (endpoint.current && file.current && fields.current) {
      try {
        buttonLabel.current = 'Uploading File'
        await uploadS3()
        notify('Syllabus Uploaded')
      } catch (e) {
        // If upload failed, fallback to previous value
        notify(`Could not upload file ${e}`, { type: 'error' })
        newData.api_url_syllabus = initialRecord.current.api_url_syllabus
      }
    }

    buttonLabel.current = 'Save'

    return filterRelationalData(newData)
  }

  return (
    <Edit {...props} title="Edit Class" component="div" transform={transform} mutationMode="pessimistic">
      <ClassForm
        onSetEndpoint={updateEndpoint}
        onSetFile={updateFile}
        onSetFields={updateFields}
        onSetRecord={updateRecord}
        saveLabel={buttonLabel.current}
        hideSyllabus={false}
      />
    </Edit>
  )
}

export default ClassEdit
