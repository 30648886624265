export const DEFAULT_LIMIT = 10
export const DEFAULT_SKIP = 0
export const DEFAULT_PAGE = 1
export const DEFAULT_SORT_FIELD = 'created_at'
export const DEFAULT_SORT_ORDER = 'ASC'
export const LARGE_PSQL_STRING_LENGTH = 2000
export const DEFAULT_PSQL_STRING_LENGTH = 255

export const PRIMARY_COLOR = '#009999'
export const DISABLED_BG = '#ddd'
export const DISABLED_COLOR = '#666'

const {
  REACT_APP_LEARNING_ENDPOINT,
  REACT_APP_LEARNING_VERSION,
  REACT_APP_ADMIN_ENDPOINT,
  REACT_APP_ADMIN_VERSION,
  REACT_APP_SENTRY_DSN,
  REACT_APP_COMMIT_HASH,
  REACT_APP_ENV,
} = process.env

export const LEARNING_SERVICE_ENDPOINT = `${REACT_APP_LEARNING_ENDPOINT}/${REACT_APP_LEARNING_VERSION}`
export const ADMIN_SERVICE_ENDPOINT = `${REACT_APP_ADMIN_ENDPOINT}/${REACT_APP_ADMIN_VERSION}`

export const SENTRY_CONFIG = {
  dsn: REACT_APP_ENV === 'production' || REACT_APP_ENV === 'staging' ? REACT_APP_SENTRY_DSN : '',
  environment: REACT_APP_ENV,
  release: REACT_APP_COMMIT_HASH,
}

export const AUTHENTICATED_ROUTES = [REACT_APP_ADMIN_ENDPOINT, REACT_APP_LEARNING_ENDPOINT]

export const ACCEPTED_FILE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  '.doc',
  '.docx',
].join(',')

export const PROGRAMS_PATH = '/programs'
export const PROGRAMS_RESOURCE = 'programs'

export const FAILURE_MESSAGE_EXPORT =
  'Error creating the Classes export. Please try again later. If issues persist, please reach out to productsupport@apiabroad.com.'
export const SUCCESS_MESSAGE_EXPORT = 'Classes exported successfully!'
