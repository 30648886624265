import * as React from 'react'
import { Alert } from '@material-ui/lab'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      marginTop: '15px',
      padding: '0 10px !important',
      boxSizing: 'border-box',
      '& .MuiPaper-root': {
        padding: '10px !important',
      },
    },
    error: {
      padding: '5px !important',
    },
  }),
)

type ErrorProps = {
  errors: any[]
}

const Error: React.FC<ErrorProps> = ({ errors }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {errors.map(item => {
        return (
          <Alert key={item} className={classes.error} severity="error">
            {item}
          </Alert>
        )
      })}
    </div>
  )
}

export default Error
