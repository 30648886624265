export const unionBy = (...arrays) => {
  const iteratee = arrays.pop()

  if (Array.isArray(iteratee)) {
    return [] // return empty if iteratee is missing
  }

  return [...arrays].flat().filter(
    (
      set => o =>
        set.has(iteratee(o)) ? false : set.add(iteratee(o))
    )(new Set()),
  )
}

export const arrayIsNotEmpty = (arr: any[]) => Array.isArray(arr) && arr.length > 0
