import ClassList from './ClassList'
import ClassEdit from './ClassEdit'
import { ClassIcon } from 'components/Icons'
import ClassCreate from './ClassCreate'

const classes = {
  list: ClassList,
  edit: ClassEdit,
  create: ClassCreate,
  icon: ClassIcon,
}

export default classes
