import * as React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { ListItemIcon, MenuItem, useMediaQuery } from '@material-ui/core'
import { Theme, makeStyles } from '@material-ui/core/styles'

import ExitIcon from '@material-ui/icons/PowerSettingsNew'

const useStyles = makeStyles(
  (theme: Theme) => ({
    menuItem: {
      color: theme.palette.text.secondary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'RaLogout' },
)

/**
 * Logout button component, to be passed to the Admin component
 *
 * Used for the Logout Menu item in the sidebar
 */
const LogoutButton = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const { oktaAuth } = useOktaAuth()

  const logout = (): Promise<void> => {
    oktaAuth.tokenManager.clear()
    return oktaAuth.signOut()
  }

  return (
    <MenuItem className={classes.menuItem} onClick={logout} component={isXSmall ? 'span' : 'li'}>
      <ListItemIcon className={classes.icon}>
        <ExitIcon />
      </ListItemIcon>
      Logout
    </MenuItem>
  )
})

export default LogoutButton
