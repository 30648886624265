import * as React from 'react'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  inputBase: {
    height: '3em',
    width: '100%',
    borderRadius: '5px 5px 0 0',
    textTransform: 'capitalize',
    [`& div[class*='MuiFilledInput-root']`]: {
      height: '48px',
    },
  },
}))

type BulkEditTextInputProps = {
  label: string
  placeholder?: string
  id?: string
  name: string
  value?: string
  required?: boolean
  onChange?: any
}

const BulkEditTextInput: React.FC<BulkEditTextInputProps> = ({
  label,
  placeholder,
  id,
  name,
  value,
  required = false,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <TextField
      label={label}
      name={name}
      id={id}
      value={value}
      type="number"
      placeholder={placeholder}
      className={classes.inputBase}
      required={required}
      onChange={onChange}
      variant="filled"
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    />
  )
}

export default BulkEditTextInput
