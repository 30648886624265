import React from 'react'
import { Record, TextField, List } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { DEFAULT_LIMIT, DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from 'utils/constants'
import { makeStyles } from '@material-ui/styles'
import Datagrid from 'components/MaterialUICore/Datagrid'

type ProgramArchiveConfirmationMessageProps = {
  record: Record
}

const useStyles = makeStyles({
  list: {
    '& div:first-child': {
      boxShadow: 'none',
      border: 'none',
      justifyContent: 'center',
      '& span div:first-child>div:first-child': {
        display: 'none',
      },
    },
  },
  noItemsMessage: {
    padding: '30px',
    alignSelf: 'center',
    justifySelf: 'center',
    textAlign: 'center',
  },
})

const ProgramArchiveConfirmationMessage: React.FC<ProgramArchiveConfirmationMessageProps> = ({ record }) => {
  const programFilter = {
    programs_ids: record.id,
  }

  const classes = useStyles()

  return (
    <>
      <Typography>{`Archiving this program - ${record.name} - will remove it’s association with the following classes:`}</Typography>
      <List
        className={classes.list}
        perPage={DEFAULT_LIMIT}
        filter={programFilter}
        sort={{ field: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER }}
        basePath="/classes"
        hasCreate={false}
        bulkActionButtons={false}
        hasEdit={false}
        exporter={false}
        hasShow={false}
        resource="classes"
        actions={false}
      >
        <Datagrid
          hasBulkActions={false}
          empty={
            <Typography className={classes.noItemsMessage}>
              There are no Classes associated with this program
            </Typography>
          }
        >
          <TextField label="Class title" source="title" />
        </Datagrid>
      </List>
    </>
  )
}

export default ProgramArchiveConfirmationMessage
