import * as React from 'react'
import { Datagrid as RADatagrid, DatagridProps } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  dataGrid: {
    '& tbody tr': {
      height: '65px',
    },
    '& thead tr': {
      height: '65px',
      color: '#3a3b3f',
      '& th': {
        color: '#66788a',
      },
    },
  },
})

const Datagrid: React.FC<DatagridProps> = props => {
  const classes = useStyles()
  return <RADatagrid className={classes.dataGrid} {...props} />
}

export default Datagrid
