import { Admin, Resource, Title } from 'react-admin'
import * as React from 'react'

// Components
import Dashboard from './pages/dashboard/Dashboard'
import CustomLayout from './components/CustomLayout/CustomLayout'
import students from './pages/students'
import countries from './pages/countries'
import hostInstitutions from './pages/hostInstitutions'
import programs from './pages/programs'
import classes from './pages/classes'
import { theme } from './theme'

// React Admin Providers
import dataProvider from './providers'

export const AdminApp: React.FC = () => {
  return (
    <Admin theme={theme} disableTelemetry dashboard={Dashboard} dataProvider={dataProvider} layout={CustomLayout}>
      <Title title="Admin" />
      <Resource name="students" {...students} />
      <Resource name="countries" {...countries} />
      <Resource name="host_institutions" {...hostInstitutions} />
      <Resource name="programs" {...programs} />
      <Resource name="classes" {...classes} />
      <Resource name="credit_units" />
      <Resource name="source_systems" />
      <Resource name="terms" />
      <Resource name="cip_codes" />
    </Admin>
  )
}

export default AdminApp
