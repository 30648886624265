import * as React from 'react'
import { List, TextField, DateField, TopToolbar, FilterButton } from 'react-admin'
import Datagrid from 'components/MaterialUICore/Datagrid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'

import hostInstitutionFilters from './HostInstitutionFilters'
import { DEFAULT_LIMIT } from 'utils/constants'

const useStyles = makeStyles({
  filters: {
    '& form': {
      margin: '0 0 24px 0',
      width: '80%',
      paddingTop: '24px',
      '& div': {
        maxWidth: '630px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'right',
      },
      '& [class*="RaFilterFormInput-spacer"]': {
        display: 'none',
      },
    },
    '& .search-query-input': {
      maxWidth: '555px',
    },
  },
})

const ListActions: React.FC = () => {
  return (
    <Box width="75%">
      <TopToolbar>
        <FilterButton />
      </TopToolbar>
    </Box>
  )
}

const rowClick = () => {
  return 'show'
}

const HostInstitutionList: React.FC = props => {
  const classes = useStyles()
  return (
    <List
      {...props}
      className={classes.filters}
      perPage={DEFAULT_LIMIT}
      sort={{ field: 'created_at', order: 'ASC' }}
      bulkActionButtons={false}
      filters={hostInstitutionFilters}
      actions={<ListActions />}
    >
      <Datagrid rowClick={rowClick}>
        <TextField label="Name" source="name" />
        <TextField label="Country" source="country.name" />
        <DateField label="Created Date" source="created_at" />
        <DateField label="Last Updated" source="updated_at" />
        <TextField label="Updated By" source="updated_by" />
      </Datagrid>
    </List>
  )
}

export default HostInstitutionList
