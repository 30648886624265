import * as React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import FullPageErrorFallback from 'components/FullPageErrorFallback'

type OnAuthResumeFunction = () => void
type Props = { error: Error }
const OktaError: React.FC<Props> = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}:{error.message}
      </p>
    )
  }
  return (
    <p>
      Error:
      {error.toString()}
    </p>
  )
}

type CustomLoginCallbackProps = {
  errorComponent?: React.ComponentType<{ error: Error }>
  onAuthResume?: OnAuthResumeFunction
}
const CustomLoginCallback: React.FC<CustomLoginCallbackProps> = ({ errorComponent, onAuthResume }) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [callbackError, setCallbackError] = React.useState(null)

  const authStateReady = authState && !authState.isPending
  const ErrorReporter = errorComponent || OktaError

  React.useEffect(() => {
    oktaAuth.handleLoginRedirect().catch(e => {
      setCallbackError(e)
    })
  }, [oktaAuth, onAuthResume])

  const authError = authStateReady ? authState.error : null
  const displayError = callbackError || authError

  if (errorComponent && displayError) {
    return <ErrorReporter error={displayError} />
  }

  if (displayError) {
    return <FullPageErrorFallback error={displayError} resetErrorBoundary={() => window.location.reload()} />
  }

  return null
}

export default CustomLoginCallback
