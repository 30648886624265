import { useRecordContext, FileInput, TextInput, useMutation, useNotify, minLength, maxLength } from 'react-admin'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { useForm } from 'react-final-form'
import DeleteIcon from '@material-ui/icons/Delete'
import { LARGE_PSQL_STRING_LENGTH, ACCEPTED_FILE_TYPES } from 'utils/constants'
import { theme } from 'theme'
import { ClassPresignResponse, AWSFields } from 'types'

const MAX_SIZE_FILE = 100000000

const useStyles = makeStyles({
  syllabusButtons: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  gridItem: {
    width: '100%',
    padding: '0',
  },
  dropzone: {
    backgroundColor: 'transparent',
    textAlign: 'right',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    height: '38px',
    display: 'flex',
    alignItems: 'center',
  },
  uploadButtonGrid: {
    '& div': {
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  removeButtonGrid: {
    margin: '5px 0 0 0',
  },
  deleteIcon: {
    '&:hover': {
      color: 'red',
    },
  },
})

type SyllabusProps = {
  source: string
  onSetEndpoint?: (awsUrl: string) => void
  onSetFile?: (file: File) => void
  onSetFields?: (formFields: AWSFields) => void
}

const validateUrl = [minLength(2), maxLength(LARGE_PSQL_STRING_LENGTH)]

const ApiUrlSyllabusField = (props: SyllabusProps) => {
  const { source } = props
  const record = useRecordContext(props)
  const form = useForm()
  const classes = useStyles()
  const notify = useNotify()

  const [mutate] = useMutation()
  const removeFile = () => {
    form.change(source, null)
  }

  const presign = file =>
    mutate(
      {
        type: 'presignUrl',
        resource: 'classes',
        payload: {
          data: {
            class_id: record['id'],
            file,
          },
        },
      },
      {
        onSuccess: (response: ClassPresignResponse) => {
          form.change(source, response.data.cloudfront_url)
          props.onSetEndpoint?.(response.data.url)
          props.onSetFile?.(file)
          props.onSetFields?.(response.data.fields)
        },
        onFailure: error => {
          notify(`Can't sign file for upload: ${error}`, { type: 'warning' })
        },
      },
    )

  return (
    <Grid item className={classes.gridItem} xs={12} md={6}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9}>
          <TextInput
            fullWidth
            source={source}
            label="API URL Syllabus"
            placeholder="Enter"
            disabled
            type="url"
            validate={validateUrl}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.uploadButtonGrid}>
          <FileInput
            source="api_syllabus_file"
            label=""
            classes={{ dropZone: classes.dropzone }}
            accept={ACCEPTED_FILE_TYPES}
            minSize={1}
            parse={presign}
            maxSize={MAX_SIZE_FILE}
            placeholder={
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                className={classes.syllabusButtons}
              >
                <span className={classes.button}>Upload</span>
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={1} className={classes.removeButtonGrid}>
          <Button
            variant="text"
            className={`${classes.deleteIcon} ${classes.syllabusButtons}`}
            onClick={removeFile}
            data-testid="removeFileButton"
          >
            <DeleteIcon className={classes.button} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ApiUrlSyllabusField
