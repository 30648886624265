import * as React from 'react'
import { MenuItemLink } from 'react-admin'
import { Flag, People } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import { ClassIcon, HostInstitutionIcon, ProgramIcon } from '../Icons'

const useStyles = makeStyles(theme => ({
  menuItemIcon: {
    fill: '#66788A',
  },
}))

type StudentsMenuItemProps = React.PropsWithChildren<{ onClick?: () => void }>
const StudentsMenuItem = React.forwardRef<HTMLInputElement, StudentsMenuItemProps>((props, ref) => {
  const classes = useStyles()

  return (
    <MenuItemLink
      ref={ref}
      to="/students"
      primaryText="Students"
      leftIcon={<People className={classes.menuItemIcon} />}
      onClick={props.onClick}
      sidebarIsOpen
      data-test="students-menu-item"
    />
  )
})

type CountriesMenuItemProps = React.PropsWithChildren<{ onClick?: () => void }>
const CountriesMenuItem = React.forwardRef<any, CountriesMenuItemProps>((props, ref) => {
  const classes = useStyles()

  return (
    <MenuItemLink
      ref={ref}
      to="/countries"
      primaryText="Countries"
      leftIcon={<Flag className={classes.menuItemIcon} />}
      onClick={props.onClick}
      sidebarIsOpen
      data-test="countries-menu-item"
    />
  )
})

type HostInstitutionsMenuItemProps = React.PropsWithChildren<{
  onClick?: () => void
}>
const HostInstitutionsMenuItem = React.forwardRef<any, HostInstitutionsMenuItemProps>((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/host_institutions"
      primaryText="Host Institutions"
      leftIcon={<HostInstitutionIcon />}
      onClick={props.onClick}
      sidebarIsOpen
      data-test="host-institutions-menu-item"
    />
  )
})

type ProgramsMenuItemProps = React.PropsWithChildren<{ onClick?: () => void }>
const ProgramsMenuItem = React.forwardRef<any, ProgramsMenuItemProps>((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/programs"
      primaryText="Programs"
      leftIcon={<ProgramIcon />}
      onClick={props.onClick}
      sidebarIsOpen
      data-test="programs-menu-item"
    />
  )
})

type ClassesMenuItemProps = React.PropsWithChildren<{ onClick?: () => void }>
const ClassesMenuItem = React.forwardRef<any, ClassesMenuItemProps>((props, ref) => {
  return (
    <MenuItemLink
      ref={ref}
      to="/classes"
      primaryText="Classes"
      leftIcon={<ClassIcon />}
      onClick={props.onClick}
      sidebarIsOpen
      data-test="classes-menu-item"
    />
  )
})

export { StudentsMenuItem, CountriesMenuItem, HostInstitutionsMenuItem, ProgramsMenuItem, ClassesMenuItem }
