import React from 'react'
import { Datagrid, DatagridProps, ListProps } from 'react-admin'
import { CustomDatagridHeader } from '.'
import CustomDatagridBody from './CustomDatagridBody'
import { omit } from 'utils/objects'

const sanitizeDatagridRestProps = (props: ListProps & DatagridProps) =>
  omit(props, ['hasEdit', 'hasList', 'hasShow', 'syncWithLocation', 'bulkActionButtons'])

type CustomDatagridProps = {
  resource: string
  showSelectCheckboxes?: boolean
}

const CustomDatagrid: React.FC<CustomDatagridProps & DatagridProps & ListProps> = ({
  resource,
  rowClick,
  children,
  showSelectCheckboxes,
  ...props
}) => {
  return (
    <Datagrid
      {...sanitizeDatagridRestProps(props)}
      rowClick={rowClick}
      header={<CustomDatagridHeader {...props} resource={resource} showSelectCheckboxes={showSelectCheckboxes} />}
      children={children}
      body={<CustomDatagridBody showSelectCheckboxes={showSelectCheckboxes} />}
    />
  )
}

export default CustomDatagrid
